<template>
  <f7-block strong inset>
    <div class="flex items-center mb-6">
      <!-- Dummy element for centering -->
      <div class="flex-1" />

      <h2 class="capitalize font-bold text-3xl text-center">
        {{ side }}
      </h2>

      <div class="flex flex-1">
        <f7-button
          v-show="croppedImageLoaded"
          small
          fill
          class="ml-auto"
          @click="downloadImage()"
          >Download</f7-button
        >
      </div>
    </div>

    <slot name="betweenTitleAndImage"></slot>

    <div
      :id="`card-image-wrap-${uniqueId}`"
      class="relative flex items-center cursor-pointer"
      @click="hasUpload && startCamera()"
    >
      <!-- <img
        :id="`imageElement-${uniqueId}`"
        :src="imageSrcLocal"
        @load="calculateImageProportion($event)"
      /> -->

      <vue-magnifier
        :id="`image-src-${uniqueId}`"
        :src="imageSrcLocal"
        :mg-show="imageSrcLocal && imageSrcLocal !== defaultNoImage"
        :zoom-factor="zoomFactor"
        crossOrigin="true"
        class="w-full"
        :style="`${
          imageMax.width
            ? `max-width: ${imageMax.width / imageProportion.width}px;`
            : ''
        } ${
          imageMax.height
            ? `max-height: ${imageMax.height / imageProportion.height}px;`
            : ''
        }`"
        @image:load="calculateImageProportion($event)"
      />

      <template v-if="!croppedImageLoaded">
        <div
          v-for="(detection, index) in defects"
          :key="index"
          class="absolute w-full border border-red-600"
          :style="`
            left: ${detection.xmin / imageProportion.width}px; 
            top: ${detection.ymin / imageProportion.height}px; 
            right: ${detection.xmax / imageProportion.width}px; 
            bottom: ${detection.ymax / imageProportion.height}px;
            width: ${
              detection.xmax / imageProportion.width -
              detection.xmin / imageProportion.width
            }px;
            height: ${
              detection.ymax / imageProportion.height -
              detection.ymin / imageProportion.height
            }px;
          `"
        />
      </template>
    </div>
  </f7-block>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs, watch } from 'vue'

import { f7 } from 'framework7-vue'

import { Capacitor } from '@capacitor/core'
import { Camera, CameraSource, CameraResultType } from '@capacitor/camera'

// import cloneDeep from 'lodash.clonedeep'

import { v4 as uuidv4 } from 'uuid'

import html2canvas from 'html2canvas'

import VueMagnifier from '@/components/vue-magnifier.vue'

import defaultNoImage from '@/assets/default-no-image.svg'

type CardPhotoSide = 'front' | 'back'

const { VisionKit } = window

const { isNativePlatform, convertFileSrc } = Capacitor

export default defineComponent({
  components: {
    VueMagnifier,
  },
  props: {
    imageSrc: {
      type: String,
      default: defaultNoImage,
    },
    defects: {
      type: Array,
      default: () => [],
    },
    side: {
      type: String,
      default: '',
    },
    hasUpload: {
      type: Boolean,
      default: true,
    },
    // width: {
    //   type: [Number, String],
    //   default: 0,
    // },
    // height: {
    //   type: [Number, String],
    //   default: 0,
    // },
    // isScanCard: {
    //   type: Boolean,
    //   default: true,
    // }
    // detections: {
    //   type: Array,
    //   default: () => ({
    //     front: { defects: [] },
    //     back: { defects: [] },
    //   }),
  },
  emits: ['update:imageSrc'],
  setup(props, { emit }) {
    // const imageElement = ref<HTMLImageElement>()

    const state = reactive({
      uniqueId: uuidv4(),
      imageSrcLocal: props.imageSrc,
      imageProportion: { width: 0, height: 0 },
      imageMax: { width: 0, height: 0 },
      croppedImageLoaded: false,
      zoomFactor: computed(() => {
        const { width, height } = state.imageProportion

        const widthComputed =
          state.croppedImageLoaded && props.hasUpload ? width : width / 3
        const heightComputed =
          state.croppedImageLoaded && props.hasUpload ? height : height / 3

        const zoomFactor = Math.min(widthComputed, heightComputed)

        return zoomFactor > 1.5 ? zoomFactor : 1.5
      }),
      // imageProportion: {
      //   width: computed(() => {
      //     const image: HTMLImageElement = document.querySelector(
      //       `#imageElement-${state.uniqueId}`
      //     )

      //     return image.naturalWidth / image.clientWidth
      //   }),
      //   height: computed(() => {
      //     const image: HTMLImageElement = document.querySelector(
      //       `#imageElement-${state.uniqueId}`
      //     )

      //     return image.naturalHeight / image.clientHeight
      //   }),
      // },
    })

    const getImage = async (
      type: CameraSource.Photos | CameraSource.Camera
    ) => {
      const { webPath } = await Camera.getPhoto({
        quality: 100,
        source: type,
        resultType: CameraResultType.Uri,
        webUseInput: true,
      })

      return webPath
    }

    const scanImage = (): Promise<string> => {
      return new Promise((resolve) => {
        VisionKit.scan((images: string[]) => {
          if (images.length > 0) {
            resolve(convertFileSrc(images[0]))

            // state.imageSrc.front = convertFileSrc(images[0])

            // state.imageSrc.back = convertFileSrc(images[1])
          }
        })
      })
    }

    const calculateImageProportion = (event: Event) => {
      const setImageProportions = () => {
        const image = event.target as HTMLImageElement

        state.imageMax.width = image.naturalWidth
        state.imageMax.height = image.naturalHeight

        state.imageProportion.width = image.naturalWidth / image.clientWidth
        state.imageProportion.height = image.naturalHeight / image.clientHeight
      }

      if (state.imageSrcLocal && state.imageSrcLocal !== defaultNoImage) {
        if (props.hasUpload) setImageProportions()

        // If not default image and there are defects, which is needed for the new canvas image
        if (props.defects.length > 0 && !state.croppedImageLoaded) {
          setImageProportions()

          if (props.hasUpload) {
            f7.dialog.preloader('Processing image')
          }

          setTimeout(async () => {
            // Convert image with defect lines to canvas for zoom hover
            const canvas = await html2canvas(
              document.querySelector(`#card-image-wrap-${state.uniqueId}`),
              {
                scale: 3,
                proxy: state.imageSrcLocal,
                useCORS: true,
                allowTaint: true,
                windowWidth: document.querySelector(
                  `#card-image-wrap-${state.uniqueId}`
                ).scrollWidth,
                windowHeight: document.querySelector(
                  `#card-image-wrap-${state.uniqueId}`
                ).scrollHeight,
              }
            )

            // state.imageSrcLocal = canvas.toDataURL('image/jpeg')

            // state.croppedImageLoaded = true

            // f7.dialog.close()

            canvas.toBlob((blob) => {
              const newImg = document.createElement('img')
              const url = URL.createObjectURL(blob)

              newImg.onload = () => {
                // // no longer need to read the blob so it's revoked
                // URL.revokeObjectURL(url)

                f7.dialog.close()
              }

              newImg.src = url

              state.imageSrcLocal = url

              state.croppedImageLoaded = true
            })
          })
        }
      }
    }

    const downloadImage = () => {
      const link = document.createElement('a') // Or maybe get it from the current document
      link.href = state.imageSrcLocal
      link.download = `${props.side}.jpg`
      // document.body.appendChild(link) // Or append it whereever you want
      link.click()
      // link.remove()
    }

    const startCamera = async () => {
      if (isNativePlatform()) {
        f7.actions
          .create({
            buttons: [
              {
                text: 'Scan Photo',
                // icon: '<f7-icon f7="camera_viewfinder" size="16px" />',
                bold: true,
                onClick: async () => {
                  const image = await scanImage()

                  state.imageSrcLocal = image

                  // await showPopupManuallyCompressImages(type)

                  // await resizeImage(type)
                },
              },
              {
                text: 'Photo from Camera',
                // icon: '<f7-icon f7="camera_fill" size="16px" />',
                bold: true,
                onClick: async () => {
                  const image = await getImage(CameraSource.Camera)

                  state.imageSrcLocal = image

                  // await showPopupManuallyCompressImages(type)

                  // await resizeImage(type)
                },
              },
              {
                text: 'Photo from Library',
                // icon: '<f7-icon f7="photo" size="16px" />',
                bold: true,
                onClick: async () => {
                  const image = await getImage(CameraSource.Photos)

                  state.imageSrcLocal = image

                  // await showPopupManuallyCompressImages(type)

                  // await resizeImage(type)
                },
              },
              {
                text: 'Cancel',
                color: 'gray',
              },
            ],
          })
          .open()
      } else {
        const image = await getImage(CameraSource.Photos)

        state.imageSrcLocal = image

        // calculateImageProportion()

        // await resizeImage(type)
      }
    }

    const resetCroppedImageLoaded = () => {
      state.croppedImageLoaded = false
    }

    watch(
      () => state.imageSrcLocal,
      () => {
        emit('update:imageSrc', state.imageSrcLocal)
      }
    )

    watch(
      () => props.imageSrc,
      () => {
        state.imageSrcLocal = props.imageSrc
      }
    )

    // onMounted(() => {
    //   window.addEventListener('resize', calculateImageProportion)
    // })

    // onUnmounted(() => {
    //   window.removeEventListener('resize', calculateImageProportion)
    // })

    return {
      ...toRefs(state),
      defaultNoImage,
      startCamera,
      downloadImage,
      calculateImageProportion,
      resetCroppedImageLoaded,
    }
  },
})
</script>
