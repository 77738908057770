<template>
  <f7-page>
    <f7-navbar title="Confirm Email"></f7-navbar>
  </f7-page>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'

import { f7 } from 'framework7-vue'

import ff from '@/js/far-fetch-init'

// declare const props: {
//   token: string
// }

export default defineComponent({
  props: {
    token: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const confirmEmail = async () => {
      try {
        await ff.patch(`/confirm-email/${props.token}`)

        f7.dialog.alert('Email is now confirmed')
      } catch (error_) {
        let userMessage = ''

        const { response, error } = error_

        if (response?.status === 401) {
          userMessage = await response.text()
        } else {
          userMessage = 'Error confirming email'
        }

        ff.errorHandler({ error, userMessage })
      }
    }

    onMounted(async () => {
      await confirmEmail()
    })
  },
})
</script>
