import { defineComponent, onMounted, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
// import { Plugins } from '@capacitor/core'
import { Browser } from '@capacitor/browser';
import { f7 } from 'framework7-vue';
// import { loadScript } from '@paypal/paypal-js'
import Navbar from '@/components/navbar.vue';
import ff, { FarFetchError } from '@/js/far-fetch-init';
// Cordova In-App Purchases plugin;
const { store } = window;
export default defineComponent({
    components: {
        Navbar,
    },
    setup() {
        const globalStore = useStore();
        const state = reactive({
            user: {
                email: '',
                gradeMontlyCount: '',
            },
            contactPopupOpened: false,
            contactMessage: '',
            cardGradings: [],
            cardGradingsModalOpened: false,
            subscriptionPlansModalOpened: false,
            subscriptionPlans: [
                { title: 'Tier 1 Monthly', billingPeriodUnit: 'monthly', price: 18.99 },
                { title: 'Tier 2 Monthly', billingPeriodUnit: 'monthly', price: 29.99 },
                { title: 'Tier 3 Monthly', billingPeriodUnit: 'monthly', price: 49.99 },
                { title: 'Tier 4 Monthly', billingPeriodUnit: 'monthly', price: 84.99 },
                { title: 'Tier 1 Yearly', billingPeriodUnit: 'yearly', price: 14.99 },
                { title: 'Tier 2 Yearly', billingPeriodUnit: 'yearly', price: 23.99 },
                { title: 'Tier 3 Yearly', billingPeriodUnit: 'yearly', price: 39.99 },
                { title: 'Tier 4 Yearly', billingPeriodUnit: 'yearly', price: 67.99 },
            ],
        });
        const getUser = async () => {
            try {
                const { responseJSON } = await ff.get('/users', {
                    errorMsgNoun: 'user',
                });
                state.user = responseJSON;
            }
            catch { }
        };
        const sendContactEmail = async () => {
            f7.preloader.show();
            try {
                const { responseJSON } = await ff.post('/contact', {
                    // errorMsg: 'Error sending contact email',
                    data: { message: state.contactMessage },
                });
                state.user = responseJSON;
                f7.dialog.alert('Contact email sent');
                state.contactPopupOpened = false;
                state.contactMessage = '';
            }
            catch (error_) {
                let userMessage = 'Error sending contact email';
                if (error_ instanceof FarFetchError) {
                    const { response, error } = error_;
                    if (response?.status === 422) {
                        const responseJSON = await response?.json();
                        userMessage = Object.values(responseJSON.errors)?.[0].message;
                    }
                    ff.errorHandler({ error, response, userMessage });
                }
                else {
                    ff.errorHandler({ error: error_, userMessage });
                }
            }
            f7.preloader.hide();
        };
        const getCardGradings = async () => {
            try {
                const { responseJSON } = await ff.get('/card-grading', {
                    errorMsgNoun: 'card grading history',
                });
                state.cardGradings = responseJSON;
            }
            catch { }
        };
        const signOut = () => {
            f7.dialog.confirm(`Are you sure you want to log out?`, async () => {
                f7.dialog.preloader();
                try {
                    await ff.post('/logout', {
                        errorMsg: 'Error logging out',
                    });
                    globalStore.commit('LoginModule/signOut');
                }
                catch { }
                f7.dialog.close();
            });
        };
        // const paypalSubscribe = async () => {
        //   const paypal = await loadScript({
        //     'client-id':
        //       'AfX-QBbbgH8jGWTVRRq7h5QdzHcVPVOsPRYp1TqsC_4VGFKOnBi7tkwT7zVsu7NgEcJXDJ5hQ2EZNml2',
        //     vault: true,
        //     intent: 'subscription',
        //     // 'data-sdk-integration-source': 'button-factory',
        //   })
        //   paypal
        //     .Buttons({
        //       style: {
        //         shape: 'rect',
        //         color: 'gold',
        //         layout: 'vertical',
        //         // label: 'subscribe',
        //       },
        //       createSubscription(data, actions) {
        //         return actions.subscription.create({
        //           /* Creates the subscription */
        //           plan_id: 'P-6SG3898109351070UMC3RNXA',
        //           custom_id: localStorage.getItem('userId'),
        //         })
        //       },
        //       // async onApprove(data, actions) {
        //       //   debugger
        //       //   console.log(data.subscriptionID) // You can add optional success message for the subscriber here
        //       // },
        //       async onApprove(data) {
        //         console.log(data)
        //         debugger
        //         const { responseJSON: details } = await ff.post(
        //           '/paypal-capture-order',
        //           {
        //             data,
        //             errorMsgNoun: 'PayPal order',
        //           }
        //         )
        //         alert(`Transaction funds captured from ${details.payer_given_name}`)
        //       },
        //     })
        //     .render('#paypal-button-container-P-6SG3898109351070UMC3RNXA')
        // }
        onMounted(async () => {
            await getUser();
            // await paypalSubscribe()
        });
        return { ...toRefs(state), Browser, getUser, sendContactEmail, signOut };
    },
});
