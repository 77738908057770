<template>
  <f7-page>
    <f7-navbar title="Reset Password"></f7-navbar>

    <div
      v-if="valid && !successfulChange"
      class="mx-auto text-center max-w-max"
    >
      <f7-block strong>
        <f7-block-header class="text-2xl text-color-black">{{
          email
        }}</f7-block-header>

        <f7-list>
          <f7-list-input
            v-model:value="newPassword"
            type="password"
            outline
            floating-label
            label="New Password"
            placeholder="Enter New Password"
          />
        </f7-list>

        <f7-button class="mt-4" @click="changePassword()"
          >Change Password</f7-button
        >
      </f7-block>
    </div>
  </f7-page>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs } from 'vue'

import { f7 } from 'framework7-vue'

import ff from '@/js/far-fetch-init'

export default defineComponent({
  props: {
    token: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const state = reactive({
      email: '',
      newPassword: '',
      valid: false,
      successfulChange: false,
    })

    const getEmailFromToken = async () => {
      try {
        const { responseText } = await ff.get(`/reset-password/${props.token}`)

        state.valid = true
        state.email = responseText
      } catch (error_) {
        let userMessage = ''

        const { response, error } = error_

        if (response?.status === 401) {
          userMessage = await response.text()
        } else {
          userMessage = 'Token is invalid'
        }

        ff.errorHandler({ error, userMessage })
      }
    }

    const changePassword = async () => {
      if (!state.newPassword) {
        f7.dialog.alert('Password cannot be empty')
        return
      }
      try {
        await ff.patch(`/reset-password/${props.token}`, {
          errorMsg: 'Error changing password',
          data: { newPassword: state.newPassword },
        })

        f7.dialog.alert(
          'Password successfully changed. Token will no longer be valid.'
        )

        // window.close()

        state.successfulChange = true
      } catch {}
    }

    onMounted(async () => {
      await getEmailFromToken()
    })

    return { ...toRefs(state), changePassword }
  },
})
</script>
