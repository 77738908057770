<template>
  <f7-page :page-content="false">
    <navbar />
    <f7-page-content>
      <f7-popup v-model:opened="subscriptionPlansModalOpened" swipe-to-close>
        <f7-page>
          <f7-navbar title="Subscription Plans">
            <f7-nav-right>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <f7-block>
            <div class="grid grid-cols-4 gap-6">
              <div
                v-for="{
                  id,
                  title,
                  price,
                  billingPeriodUnit,
                } in subscriptionPlans"
                :key="id"
                class="subscription-option border-2 border-primary rounded p-6 mb-5"
                :class="{
                  'subscription-selected': selectedSubscriptionId === id,
                }"
                @click="
                  selectedSubscriptionId =
                    selectedSubscriptionId !== id ? id : ''
                "
              >
                <p class="subscription-header text-primary font-bold text-xl">
                  {{ title }}
                </p>

                <p class="subscription-pricing text-lg">
                  {{ price }} / {{ billingPeriodUnit }}
                </p>
              </div>
            </div>
          </f7-block>
        </f7-page>
      </f7-popup>

      <f7-popup v-model:opened="cardGradingsModalOpened">
        <f7-page>
          <f7-navbar title="Card Grading History">
            <f7-nav-right>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <f7-list media-list inset>
            <f7-list-item
              v-for="{ id, playerName, rating, image } in cardGradings.data"
              :key="id"
              swipeout
              :title="playerName"
              :after="rating"
            >
              <template #media>
                <img v-if="image" :src="image" style="height: 80px" />

                <f7-icon v-else size="56px" f7="folder_fill" />
              </template>
            </f7-list-item>
          </f7-list>
        </f7-page>
      </f7-popup>

      <f7-list inset>
        <f7-list-item title="Email" :after="user.email">
          <template #media>
            <f7-icon f7="envelope" size="20px" />
          </template>
        </f7-list-item>
      </f7-list>

      <!-- <f7-block-title>Card Gradings Used This Month</f7-block-title>
      <f7-block strong inset class="flex">
        <f7-gauge
          type="circle"
          :value="user.gradeMontlyCount / 5"
          :value-text="`${user.gradeMontlyCount}/5`"
          size="100"
          value-text-color="var(--f7-theme-color)"
          border-color="var(--f7-theme-color)"
        />
      </f7-block>

      <f7-list inset>
        <f7-list-item
          title="Subscription Plan"
          link="#"
          after="Base"
          @click="subscriptionPlansModalOpened = true"
        />
      </f7-list> -->

      <f7-list inset>
        <f7-list-item
          title="Privacy Policy"
          link="#"
          @click="
            Browser.open({
              url: 'https://www.playergraders.com/terms-of-service-3',
            })
          "
        />
        <f7-list-item
          title="Terms of Service"
          link="#"
          @click="
            Browser.open({
              url: 'https://www.playergraders.com/terms-of-service-3',
            })
          "
        />
        <f7-list-item
          title="Contact"
          link="#"
          @click="contactPopupOpened = true"
        />
      </f7-list>

      <f7-list inset>
        <f7-list-button
          title="Log Out"
          link="#"
          color="red"
          @click="signOut()"
        />
      </f7-list>

      <!-- Contact Form Popup -->
      <f7-popup v-model:opened="contactPopupOpened" swipe-to-close>
        <f7-page>
          <f7-navbar>
            <f7-nav-left>
              <img
                style="height: 25px; width: auto; padding: 0px 12px"
                class="mx-auto"
                src="@/assets/logo-icon.png"
              />
            </f7-nav-left>
            <f7-nav-title>Contact</f7-nav-title>
            <f7-nav-right>
              <f7-link @click="contactPopupOpened = false">Close</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <f7-list>
            <f7-list-input
              v-model:value="contactMessage"
              required
              validate
              outline
              floating-label
              label="Message"
              type="textarea"
              placeholder="Type message here..."
            />
          </f7-list>

          <f7-block strong>
            <f7-button fill text="Submit" @click="sendContactEmail()" />
          </f7-block>

          <div style="text-align: center">
            <img
              style="width: 150px; margin-top: 35px"
              class="mx-auto"
              src="@/assets/logo.jpg"
            />
          </div>
        </f7-page>
      </f7-popup>

      <!-- <div id="paypal-button-container-P-6SG3898109351070UMC3RNXA"></div> -->
    </f7-page-content>
  </f7-page>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

// import { Plugins } from '@capacitor/core'
import { Browser } from '@capacitor/browser'

import { f7 } from 'framework7-vue'

// import { loadScript } from '@paypal/paypal-js'

import Navbar from '@/components/navbar.vue'

import ff, { FarFetchError } from '@/js/far-fetch-init'

declare global {
  interface Window {
    store: any
  }
}

// Cordova In-App Purchases plugin;
const { store } = window

export default defineComponent({
  components: {
    Navbar,
  },
  setup() {
    const globalStore = useStore()

    const state = reactive({
      user: {
        email: '',
        gradeMontlyCount: '',
      },
      contactPopupOpened: false,
      contactMessage: '',
      cardGradings: [],
      cardGradingsModalOpened: false,
      subscriptionPlansModalOpened: false,
      subscriptionPlans: [
        { title: 'Tier 1 Monthly', billingPeriodUnit: 'monthly', price: 18.99 },
        { title: 'Tier 2 Monthly', billingPeriodUnit: 'monthly', price: 29.99 },
        { title: 'Tier 3 Monthly', billingPeriodUnit: 'monthly', price: 49.99 },
        { title: 'Tier 4 Monthly', billingPeriodUnit: 'monthly', price: 84.99 },
        { title: 'Tier 1 Yearly', billingPeriodUnit: 'yearly', price: 14.99 },
        { title: 'Tier 2 Yearly', billingPeriodUnit: 'yearly', price: 23.99 },
        { title: 'Tier 3 Yearly', billingPeriodUnit: 'yearly', price: 39.99 },
        { title: 'Tier 4 Yearly', billingPeriodUnit: 'yearly', price: 67.99 },
      ],
    })

    const getUser = async () => {
      try {
        const { responseJSON } = await ff.get('/users', {
          errorMsgNoun: 'user',
        })

        state.user = responseJSON
      } catch {}
    }

    const sendContactEmail = async () => {
      f7.preloader.show()

      try {
        const { responseJSON } = await ff.post('/contact', {
          // errorMsg: 'Error sending contact email',
          data: { message: state.contactMessage },
        })

        state.user = responseJSON

        f7.dialog.alert('Contact email sent')

        state.contactPopupOpened = false
        state.contactMessage = ''
      } catch (error_) {
        let userMessage = 'Error sending contact email'

        if (error_ instanceof FarFetchError) {
          const { response, error } = error_

          if (response?.status === 422) {
            const responseJSON: Record<
              'errors',
              Record<string, string>[]
            > = await response?.json()

            userMessage = Object.values(responseJSON.errors)?.[0].message
          }

          ff.errorHandler({ error, response, userMessage })
        } else {
          ff.errorHandler({ error: error_, userMessage })
        }
      }

      f7.preloader.hide()
    }

    const getCardGradings = async () => {
      try {
        const { responseJSON } = await ff.get('/card-grading', {
          errorMsgNoun: 'card grading history',
        })

        state.cardGradings = responseJSON
      } catch {}
    }

    const signOut = () => {
      f7.dialog.confirm(`Are you sure you want to log out?`, async () => {
        f7.dialog.preloader()

        try {
          await ff.post('/logout', {
            errorMsg: 'Error logging out',
          })

          globalStore.commit('LoginModule/signOut')
        } catch {}

        f7.dialog.close()
      })
    }

    // const paypalSubscribe = async () => {
    //   const paypal = await loadScript({
    //     'client-id':
    //       'AfX-QBbbgH8jGWTVRRq7h5QdzHcVPVOsPRYp1TqsC_4VGFKOnBi7tkwT7zVsu7NgEcJXDJ5hQ2EZNml2',
    //     vault: true,
    //     intent: 'subscription',
    //     // 'data-sdk-integration-source': 'button-factory',
    //   })

    //   paypal
    //     .Buttons({
    //       style: {
    //         shape: 'rect',
    //         color: 'gold',
    //         layout: 'vertical',
    //         // label: 'subscribe',
    //       },
    //       createSubscription(data, actions) {
    //         return actions.subscription.create({
    //           /* Creates the subscription */
    //           plan_id: 'P-6SG3898109351070UMC3RNXA',
    //           custom_id: localStorage.getItem('userId'),
    //         })
    //       },
    //       // async onApprove(data, actions) {
    //       //   debugger

    //       //   console.log(data.subscriptionID) // You can add optional success message for the subscriber here
    //       // },
    //       async onApprove(data) {
    //         console.log(data)

    //         debugger

    //         const { responseJSON: details } = await ff.post(
    //           '/paypal-capture-order',
    //           {
    //             data,
    //             errorMsgNoun: 'PayPal order',
    //           }
    //         )

    //         alert(`Transaction funds captured from ${details.payer_given_name}`)
    //       },
    //     })
    //     .render('#paypal-button-container-P-6SG3898109351070UMC3RNXA')
    // }

    onMounted(async () => {
      await getUser()

      // await paypalSubscribe()
    })

    return { ...toRefs(state), Browser, getUser, sendContactEmail, signOut }
  },
})
</script>

<style>
.subscription-option.subscription-selected {
  background-color: var(--f7-theme-color-text-color);
}

.subscription-option.subscription-selected .subscription-header {
  color: #fff;
}

.subscription-option.subscription-selected .subscription-pricing {
  color: gray;
}
</style>
