<template>
  <f7-page :page-content="false">
    <navbar />
    <f7-page-content>
      <!-- <f7-navbar>
      <f7-nav-left>
        <f7-link icon-f7="folder" @click="folderActionSheetOpeend = true" />

        <f7-link v-if="!editViewShown" @click="editViewShown = true"
          >Edit</f7-link
        >

        <template v-else>
          <f7-link @click="editViewShown = false">Cancel</f7-link>

          <f7-link
            v-if="currentFolderIdSelected"
            @click="updateFolder(currentFolderIdSelected)"
            >Rename Folder</f7-link
          >

          <f7-link v-if="currentFolderIdSelected" @click="deleteFolder()"
            >Delete Folder</f7-link
          >
        </template>
      </f7-nav-left>
      <f7-nav-title>Saved Cards</f7-nav-title>
      <f7-nav-right>
        <f7-link icon-f7="search" @click="onSearchOpen()"></f7-link>
      </f7-nav-right>

      <f7-searchbar
        :id="`search-bar-${uniqueId}`"
        v-model:value="searchText"
        expandable
        custom-search
      />
    </f7-navbar> -->

      <!-- Folder Action Sheet -->
      <f7-actions v-model:opened="folderActionSheetOpeend">
        <f7-actions-group>
          <f7-actions-button bold @click="addFolder()"
            >Add Folder</f7-actions-button
          >
          <f7-actions-button>Rename Folder</f7-actions-button>
          <f7-actions-button>Delete Folder</f7-actions-button>
        </f7-actions-group>
        <f7-actions-group>
          <f7-actions-button color="gray">Cancel</f7-actions-button>
        </f7-actions-group>
      </f7-actions>

      <!-- <f7-block class="mb-0">
        <f7-input type="select" class="ml-auto w-auto">
          <option value="folder">Folder View</option>
          <option value="all">All Cards View</option>
        </f7-input>
      </f7-block> -->

      <f7-block strong class="flex">
        <div class="flex-1">
          <f7-button
            fill
            raised
            icon-f7="plus_circle_fill"
            icon-size="16px"
            text="Add New Folder"
            class="inline-block mr-4"
            @click="addFolder()"
          />

          <f7-button
            v-if="!editViewShown"
            fill
            raised
            text="Edit Options"
            class="inline-block mr-4"
            @click="showEditView()"
          />

          <template v-else>
            <!-- <f7-link @click="editViewShown = false">Cancel</f7-link> -->

            <f7-button
              fill
              raised
              text="Cancel"
              color="gray"
              class="inline-block mr-4"
              @click="closeEditOptions()"
            />

            <f7-button
              fill
              raised
              icon-f7="square_pencil"
              icon-size="16px"
              text="Rename"
              class="inline-block mr-4"
              :class="{ disabled: !currentFolderIdSelected }"
              @click="updateFolder(currentFolderIdSelected)"
            />

            <f7-button
              fill
              raised
              icon-f7="trash_fill"
              icon-size="16px"
              text="Delete"
              class="inline-block"
              :class="{ disabled: !currentFolderIdSelected }"
              @click="deleteFolder()"
            />
          </template>
        </div>

        <f7-searchbar
          v-model:value="searchText"
          inline
          custom-search
          :disable-button="false"
        />

        <!-- Dummy element for centering purposes -->
        <div class="flex-1" />
      </f7-block>

      <f7-block strong>
        <div class="mx-auto" style="max-width: 1000px">
          <!-- Default Folder -->
          <f7-list media-list no-hairlines>
            <f7-list-item
              link="/folder-inner/default-folder"
              :route-props="{ folderName: 'Default Folder' }"
              title="Default Folder"
              :after="`${folders.default.total}`"
            >
              <template #media>
                <img
                  src="@/assets/folder-icon.png"
                  style="width: 56px; height: 56px"
                />
                <!-- <f7-icon size="56px" f7="folder_fill" /> -->
              </template>
            </f7-list-item>
          </f7-list>

          <!-- Folder List -->
          <f7-list v-if="folders.folders.data.length > 0" media-list>
            <template
              v-for="(folderTypeValue, folderTypeName) in folders"
              :key="folderTypeName"
            >
              <template v-if="folderTypeName === 'folders'">
                <f7-list-item
                  v-for="{ id, name, slug, total } in folderTypeValue.data"
                  :key="id"
                  :checked="currentFolderIdSelected === id"
                  :value="id"
                  :radio="editViewShown"
                  :swipeout="isNativePlatform()"
                  :link="!editViewShown ? `/folder-inner/${slug}` : null"
                  :route-props="{ folderName: name }"
                  :title="name"
                  :after="`${total}`"
                  @change="
                    currentFolderIdSelected = Number($event.target.value)
                  "
                >
                  <f7-swipeout-actions v-if="isNativePlatform()" left>
                    <f7-swipeout-button
                      close
                      color="green"
                      @click="updateFolder(id)"
                      >Edit</f7-swipeout-button
                    >
                  </f7-swipeout-actions>

                  <template #media>
                    <!-- <img v-if="image" :src="image" style="height: 80px" /> -->

                    <img
                      src="@/assets/folder-icon.png"
                      style="width: 56px; height: 56px"
                    />

                    <!-- <f7-icon size="56px" f7="folder_fill" /> -->
                  </template>
                </f7-list-item>
              </template>
            </template>
          </f7-list>

          <!-- Trash Folder -->
          <f7-list media-list no-hairlines>
            <f7-list-item
              title="Trash"
              :after="`${folders.trash.total}`"
              link="/trash-inner/"
            >
              <template #media>
                <f7-icon size="56px" f7="trash_fill" />
              </template>
            </f7-list-item>
          </f7-list>
        </div>
      </f7-block>
    </f7-page-content>
  </f7-page>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
} from 'vue'

import { f7 } from 'framework7-vue'

import { Capacitor } from '@capacitor/core'

import ff from '@/js/far-fetch-init'
import { useStore } from 'vuex'

import { v4 as uuidv4 } from 'uuid'

import kebabCase from 'lodash.kebabcase'

import Navbar from '@/components/navbar.vue'

const { isNativePlatform } = Capacitor

export default defineComponent({
  components: { Navbar },
  setup() {
    // const searchBar = ref()

    const globalStore = useStore()

    const state = reactive({
      searchText: '',
      folderActionSheetOpeend: false,
      editViewShown: false,
      currentFolderIdSelected: 0,
      // folders: { default: { total: 0 }, folders: { data: [], meta: [] } },
      folders: computed(() => globalStore.state.FolderModule.folders),
      uniqueId: uuidv4(),
    })

    const getFolders = async () => {
      try {
        const { responseJSON } = await ff.get('/folders', {
          errorMsgNoun: 'folders',
        })

        state.folders = responseJSON
      } catch {}
    }

    const addFolder = () => {
      f7.dialog.prompt('Folder Name', async (folderName) => {
        try {
          const { responseJSON } = await ff.post('/folders', {
            data: { name: folderName, slug: kebabCase(folderName) },
            // errorMsgNoun: 'folders',
          })

          state.folders.folders.data.unshift(responseJSON)
        } catch (error_) {
          let userMessage = ''

          const { response, error } = error_

          if (response?.status === 422) {
            const responseJSON: Record<
              'errors',
              Record<string, string>[]
            > = await response?.json()

            userMessage = Object.values(responseJSON.errors)?.[0].message
          } else {
            userMessage = 'Error adding folder'
          }

          ff.errorHandler({ error, response, userMessage })
        }
      })
    }

    const updateFolder = (id: number) => {
      f7.dialog.prompt('Folder Name', async (folderName) => {
        try {
          await ff.patch(`/folders/${id}`, {
            data: { name: folderName, slug: kebabCase(folderName) },
            // errorMsgNoun: 'folder',
          })

          const folderIndex = state.folders.folders.data.findIndex(
            ({ id: folderId }) => folderId === id
          )

          state.folders.folders.data[folderIndex].name = folderName

          // state.folders.folders.data.unshift(responseJSON)
        } catch (error_) {
          let userMessage = ''

          const { response, error } = error_

          if (response?.status === 422) {
            const responseJSON: Record<
              'errors',
              Record<string, string>[]
            > = await response?.json()

            userMessage = Object.values(responseJSON.errors)?.[0].message
          } else {
            userMessage = 'Error updating folder'
          }

          ff.errorHandler({ error, response, userMessage })
        }
      })
    }

    const deleteFolder = () => {
      f7.dialog.confirm('Are you sure you want to delete folder?', async () => {
        try {
          await ff.delete(`/folders/${state.currentFolderIdSelected}`, {
            errorMsgNoun: 'folder',
          })

          state.folders.folders.data = state.folders.folders.data.filter(
            ({ id }) => id !== state.currentFolderIdSelected
          )

          f7.dialog.alert('Folders are deleted', () => {
            const { router } = f7.views.current

            router.navigate({ name: 'trash-inner' })
          })

          // state.folders.folders.data.unshift(responseJSON)
        } catch {}
      })
    }

    const onSearchOpen = () => {
      const searchBar = f7.searchbar.get(`#search-bar-${state.uniqueId}`)

      searchBar.inputEl.focus()

      searchBar.search(state.searchText).enable()
    }

    const onSearch = async () => {
      try {
        const { responseJSON } = await ff.get('/folders', {
          data: {
            filters: JSON.stringify({ name: state.searchText }),
          },
          errorMsgNoun: 'folders',
        })

        globalStore.commit('FolderModule/setFolders', responseJSON)

        // state.folders = responseJSON
      } catch {}
    }

    const showEditView = () => {
      if (state.folders.folders.data.length === 0) {
        f7.dialog.alert('No custom folders added.')

        return
      }

      state.editViewShown = true
    }

    const closeEditOptions = () => {
      state.editViewShown = false

      state.currentFolderIdSelected = 0
    }

    watch(
      () => state.searchText,
      async () => {
        await onSearch()
      }
    )

    onMounted(async () => {
      console.log('saved cards mounted')

      await globalStore.dispatch('FolderModule/getFolders')

      console.log(state.folders)

      // await getFolders()
    })

    return {
      ...toRefs(state),
      kebabCase,
      isNativePlatform,
      onSearchOpen,
      onSearch,
      showEditView,
      closeEditOptions,
      getFolders,
      addFolder,
      updateFolder,
      deleteFolder,
    }
  },
})
</script>
