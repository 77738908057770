// import Home from '@/pages/home.vue'

import FolderInner from '@/pages/folder-inner.vue'
import CardInner from '@/pages/card-inner.vue'
import TrashInner from '@/pages/trash-inner.vue'
import ScanCard from '@/pages/scan-card.vue'
import SavedCards from '@/pages/saved-cards.vue'
import Settings from '@/pages/settings.vue'
import ConfirmEmail from '@/pages/confirm-email.vue'
import ResetPassword from '@/pages/reset-password.vue'

import NotFoundPage from '@/pages/404.vue'

const routes = [
  // {
  //   path: '/',
  //   component: Home,
  //   tabs: [
  {
    id: 'scan-card-tab',
    name: 'scan-card',
    path: '/',
    component: ScanCard,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    id: 'saved-cards-tab',
    name: 'saved-cards',
    path: '/saved-cards',
    component: SavedCards,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    name: 'folder-inner',
    path: '/folder-inner/:folderNameSlug',
    component: FolderInner,
    options: {
      transition: 'f7-fade',
      // props: {
      //   folderName: '',
      // },
    },
  },
  {
    name: 'card-inner',
    path: '/card-inner/:cardId',
    component: CardInner,
    options: {
      transition: 'f7-fade',
      props: {
        folderName: '',
      },
    },
  },
  {
    name: 'trash-inner',
    path: '/trash-inner',
    component: TrashInner,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    id: 'settings-tab',
    name: 'settings',
    path: '/settings',
    component: Settings,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    name: 'confirm-email',
    path: '/confirm-email/:token',
    component: ConfirmEmail,
  },
  {
    name: 'reset-password',
    path: '/reset-password/:token',
    component: ResetPassword,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
  // ],
  // },
]

export default routes
