import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        folders: {
            type: Object,
            default: () => ({}),
        },
    },
    emits: ['update:folders', 'click:folder'],
    // setup(props, { emit }) {
    //   const state = reactive({
    //     foldersLocal: cloneDeep(props.folders),
    //   })
    //   watch(
    //     () => state.foldersLocal,
    //     () => {
    //       emit('update:folders', state.foldersLocal)
    //     },
    //     { deep: true }
    //   )
    //   watch(
    //     () => props.folders,
    //     () => {
    //       state.foldersLocal = cloneDeep(props.folders)
    //     },
    //     { deep: true }
    //   )
    //   return { ...toRefs(state) }
    // },
});
