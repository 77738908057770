import { defineComponent, onMounted, reactive, toRefs } from 'vue';
import ff from '@/js/far-fetch-init';
export default defineComponent({
    setup() {
        const state = reactive({
            trashItems: { cards: [], folders: [] },
            checkedItems: { cardIds: [], folderIds: [] },
            isIndeterminate: false,
            editViewShown: false,
        });
        const getTrashItems = async () => {
            try {
                const { responseJSON } = await ff.get('/trash', {
                    errorMsgNoun: 'trash items',
                });
                state.trashItems = responseJSON;
            }
            catch { }
        };
        const restoreItems = async () => {
            try {
                await ff.patch('/trash', {
                    errorMsgNoun: 'trash items',
                    data: { items: state.checkedItems },
                });
                await getTrashItems();
            }
            catch { }
        };
        const deleteItems = async () => {
            try {
                await ff.delete('/trash', {
                    errorMsgNoun: 'trash items',
                    data: { items: state.checkedItems },
                });
                await getTrashItems();
            }
            catch { }
        };
        const onCheckChange = ({ event, folderId = 0, type, }) => {
            const { checked, value } = event.target;
            const valueFormatted = parseInt(value, 10);
            let folderIdFormatted;
            let folderObject;
            let cardIdsInFolder;
            if (folderId || type === 'folderIds') {
                // Use folderId variable if card
                folderIdFormatted = type === 'folderIds' ? valueFormatted : folderId;
                // Folder object by id
                folderObject = state.trashItems.folders.find(({ id }) => id === folderIdFormatted);
                cardIdsInFolder = folderObject.cards.map(({ id }) => id);
            }
            if (checked) {
                state.checkedItems[type].push(valueFormatted);
                if (type === 'folderIds') {
                    state.checkedItems.cardIds.push(...cardIdsInFolder);
                }
                else if (type === 'cardIds' && folderId) {
                    const allCardsInFolderChecked = state.checkedItems.cardIds.every((id) => cardIdsInFolder.includes(id));
                    // Set folder to checked if all cards checked
                    if (allCardsInFolderChecked) {
                        state.checkedItems.folderIds.push(folderIdFormatted);
                    }
                }
            }
            else {
                state.checkedItems[type].splice(state.checkedItems[type].indexOf(valueFormatted), 1);
                if (type === 'folderIds') {
                    state.checkedItems.cardIds = state.checkedItems.cardIds.filter((id) => !cardIdsInFolder.includes(id));
                }
                else if (type === 'cardIds' && folderId) {
                    const anyCardInFolderChecked = state.checkedItems.cardIds.some((id) => cardIdsInFolder.includes(id));
                    // Set folder to unchecked if all cards unchecked
                    if (!anyCardInFolderChecked) {
                        state.checkedItems.folderIds.splice(state.checkedItems.folderIds.indexOf(folderId), 1);
                    }
                }
            }
            if (type === 'folderIds') {
                const allCardsInFolderChecked = cardIdsInFolder.every((id) => state.checkedItems.cardIds.includes(id));
                const someCardsInFolderChecked = cardIdsInFolder.some((id) => state.checkedItems.cardIds.includes(id));
                // If not all cards checked, but some are
                if (!allCardsInFolderChecked && someCardsInFolderChecked) {
                    state.isIndeterminate = true;
                }
                else {
                    state.isIndeterminate = false;
                }
            }
        };
        onMounted(async () => {
            await getTrashItems();
        });
        return { ...toRefs(state), restoreItems, deleteItems, onCheckChange };
    },
});
