<template>
  <f7-app v-bind="f7params">
    <f7-views tabs class="safe-areas">
      <f7-toolbar class="hidden" tabbar labels bottom>
        <f7-link
          id="scan-card-tab"
          tab-link
          :tab-link-active="tabLinkActive.scanCard"
          href="/"
          icon-f7="viewfinder"
          text="Scan Card"
          @click="setActiveTab()"
        />

        <f7-link
          id="save-cards-tab"
          tab-link
          :tab-link-active="tabLinkActive.savedCards"
          href="/saved-cards/"
          icon-f7="folder_fill"
          text="Saved Cards"
          @click="setActiveTab()"
        />

        <f7-link
          id="settings-tab"
          tab-link
          :tab-link-active="tabLinkActive.settings"
          href="/settings/"
          icon-f7="gear"
          text="Settings"
          @click="setActiveTab()"
        />
      </f7-toolbar>

      <f7-view main :load-initial-page="false" :stack-pages="true"></f7-view>
    </f7-views>

    <!-- Views/Tabs container -->
    <!-- <f7-views tabs class="safe-areas">
      <f7-toolbar tabbar labels bottom>
        <f7-link
          tab-link="#view-home"
          icon-f7="viewfinder"
          text="Scan Card"
          @click="onHomeTabClicked()"
        />

        <f7-link
          tab-link="#view-saved-cards"
          icon-f7="folder_fill"
          text="Saved Cards"
          @click="onSavedCardsTabClicked()"
        />

        <f7-link
          tab-link="#view-settings"
          icon-f7="gear"
          text="Settings"
          @click="onSettingsTabClicked()"
        />
      </f7-toolbar>

      <f7-view id="view-home" main tab tab-active />

      <f7-view
        id="view-saved-cards"
        :load-initial-page="false"
        name="saved-cards"
        tab
      />

      <f7-view
        id="view-settings"
        name="settings"
        :load-initial-page="false"
        tab
      />
    </f7-views> -->

    <f7-login-screen
      id="login-screen-popup"
      animate
      :opened="!isLoggedIn && routeName !== 'reset-password'"
    >
      <f7-view>
        <f7-page login-screen :page-content="false">
          <f7-page-content style="background: transparent">
            <img
              style="width: 325px; margin-top: 35px"
              class="mx-auto"
              src="@/assets/logo.jpg"
            />

            <f7-list form style="margin-bottom: 0px">
              <f7-list-input
                v-model:value="loginForm.email"
                outline
                validate
                floating-label
                label="Email"
                type="email"
                placeholder="Enter Email"
              ></f7-list-input>
              <f7-list-input
                v-model:value="loginForm.password"
                outline
                floating-label
                label="Password"
                type="password"
                placeholder="Enter Password"
              ></f7-list-input>
            </f7-list>

            <f7-block style="margin-top: 0px">
              <div style="display: flex">
                <f7-link
                  style="
                    text-align: right;
                    display: inline-block;
                    font-size: 14px;
                    margin-bottom: 25px;
                    margin-left: auto;
                    color: var(--f7-theme-color);
                  "
                  href="#"
                  @click="resetPassword()"
                  >Forgot Password?</f7-link
                >
              </div>

              <f7-button fill @click="signIn()">Sign In</f7-button>
            </f7-block>

            <f7-block>
              <f7-button @click="createAccountPopupOpened = true"
                >Register</f7-button
              >
            </f7-block>
          </f7-page-content>
        </f7-page>
      </f7-view>
    </f7-login-screen>

    <!-- Create Account Popup -->
    <f7-popup v-model:opened="createAccountPopupOpened" swipe-to-close>
      <f7-view>
        <f7-page class="bg-color-white">
          <f7-navbar>
            <f7-nav-left>
              <img
                style="height: 25px; width: auto; padding: 0px 12px"
                class="mx-auto"
                src="@/assets/logo-icon.png"
              />
            </f7-nav-left>

            <f7-nav-title>
              Register
              <!-- <img style="height: 27px" src="@/assets/logo.png" /> -->
            </f7-nav-title>

            <f7-nav-right>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <!-- <div style="text-align: center">
            <img
              style="width: 150px; margin-top: 35px"
              class="mx-auto"
              src="@/assets/logo.jpg"
            />
          </div> -->

          <f7-list style="margin-top: 20px" form no-hairlines>
            <f7-list-input
              v-model:value="registerForm.firstName"
              outline
              floating-label
              type="text"
              label="First Name"
              placeholder="Enter First Name"
            ></f7-list-input>

            <f7-list-input
              v-model:value="registerForm.lastName"
              outline
              floating-label
              type="text"
              label="Last Name"
              placeholder="Enter Last Name"
            ></f7-list-input>

            <f7-list-input
              v-model:value="registerForm.email"
              outline
              floating-label
              type="email"
              label="Email"
              placeholder="Enter Email"
            ></f7-list-input>

            <f7-list-input
              v-model:value="registerForm.password"
              outline
              floating-label
              type="password"
              label="Password"
              placeholder="Enter Password"
            ></f7-list-input>
          </f7-list>

          <f7-block style="margin-top: 20px">
            <f7-button large fill class="mb-4" @click="register()"
              >Create Account</f7-button
            >

            <p style="text-align: center; font-size: 13px">
              By creating an account, you hereby accept the
              <f7-link
                href="https://www.playergraders.com/terms-of-service-3"
                class="font-semibold"
                style="color: var(--f7-theme-color)"
                >privacy policy</f7-link
              >
              and
              <f7-link
                href="https://www.playergraders.com/terms-of-service-3"
                class="font-semibold"
                style="color: var(--f7-theme-color)"
                >terms of service</f7-link
              >.
            </p>
          </f7-block>

          <div style="text-align: center">
            <img
              style="width: 150px; margin-top: 35px"
              class="mx-auto"
              src="@/assets/logo.jpg"
            />
          </div>
        </f7-page>
      </f7-view>
    </f7-popup>
  </f7-app>
</template>
<script lang="ts">
import { reactive, toRefs, onMounted, computed, watch } from 'vue'
import { useStore } from 'vuex'

import { Capacitor } from '@capacitor/core'

import { f7, f7ready } from 'framework7-vue'
import { getDevice } from 'framework7/lite-bundle'

import ff from '@/js/far-fetch-init'

// import capacitorApp from '../js/capacitor-app.js'
import routes from '../js/routes.js'
// import store from '../js/store'

declare global {
  interface Window {
    store: any
  }
}

const { isNativePlatform } = Capacitor

// // Cordova In-App Purchases plugin;
// const { store } = window

// import logo from '@/assets/logo.jpg'

export default {
  setup() {
    const globalStore = useStore()

    const device = getDevice()
    // Framework7 Parameters
    const f7params = {
      name: 'PlayerGraders', // App name
      theme: 'auto', // Automatic theme detection

      id: 'com.playergraders.app', // App bundle ID
      // App store
      // store,
      // App routes
      routes,
      view: {
        browserHistory: true,
        browserHistoryStoreHistory: true,
        browserHistorySeparator: '',
        // browserHistoryRoot: 'http://localhost:8080/',
        browserHistoryInitialMatch: true,
        browserHistoryOnLoad: false,
        stackPages: true,
      },
      // Input settings
      input: {
        scrollIntoViewOnFocus: device.capacitor,
        scrollIntoViewCentered: device.capacitor,
      },
      // Navbar settings
      navbar: {
        mdCenterTitle: true,
      },
      // Capacitor Statusbar settings
      statusbar: {
        iosOverlaysWebView: true,
        androidOverlaysWebView: false,
      },
      touch: {
        tapHold: true, // enable tap hold events
      },
    }

    const state = reactive({
      // isLoggedIn: false,
      createAccountPopupOpened: false,
      routeName: '',
      isLoggedIn: computed(() => globalStore.state.LoginModule.isLoggedIn),
      loginForm: {
        email: '',
        password: '',
      },
      registerForm: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
      },
      tabLinkActive: {
        scanCard: false,
        savedCards: false,
        settings: false,
      },
    })

    const setActiveTab = () => {
      const { router } = f7.views.current

      const routeName = router.currentRoute?.route?.name

      state.tabLinkActive = {
        scanCard: false,
        savedCards: false,
        settings: false,
      }

      if (routeName === 'saved-cards' || routeName === 'folder-inner') {
        state.tabLinkActive.savedCards = true
        ;(<HTMLElement>(
          document.querySelector('.tab-link-highlight')
        )).style.transform = 'translate3d(100%, 0px, 0px)'
      } else if (routeName === 'settings') {
        state.tabLinkActive.settings = true
        ;(<HTMLElement>(
          document.querySelector('.tab-link-highlight')
        )).style.transform = 'translate3d(200%, 0px, 0px)'
        // Everything goes in first tab (home)
      } else {
        state.tabLinkActive.scanCard = true
        ;(<HTMLElement>(
          document.querySelector('.tab-link-highlight')
        )).style.transform = 'translate3d(0%, 0px, 0px)'
      }
    }

    const onHomeTabClicked = () => {
      const view = f7.views.get('#view-home')

      // console.log(view.router.currentRoute?.route)

      view.router.navigate({ name: 'scan-card' }, { animate: false })

      view.router.updateCurrentUrl('/')
    }

    const onSavedCardsTabClicked = () => {
      const view = f7.views.get('#view-saved-cards')

      const routeName = view.router.currentRoute?.route?.name

      if (routeName !== 'folder-inner' || !routeName) {
        view.router.navigate({ name: 'saved-cards' }, { animate: false })

        view.router.updateCurrentUrl('/saved-cards/')
      } else if (routeName === 'folder-inner') {
        view.router.updateCurrentUrl(view.router?.currentRoute?.url)
      }
    }

    const onSettingsTabClicked = () => {
      const view = f7.views.get('#view-settings')

      view.router.navigate({ name: 'settings' }, { animate: false })

      view.router.updateCurrentUrl('/settings/')
    }

    const register = async () => {
      f7.dialog.preloader()

      try {
        await ff.post('/register', {
          data: state.registerForm,
        })

        // const responseJSON = await response.json()

        // localStorage.setItem('token', responseJSON.apiToken.token)
        // localStorage.setItem('userId', responseJSON.userId)

        // if (isNativePlatform) {
        //   store.applicationUsername = localStorage.getItem('userId')
        // }

        state.registerForm = {
          firstName: '',
          lastName: '',
          email: '',
          password: '',
        }

        state.createAccountPopupOpened = false

        f7.dialog.alert('Please confirm your account by email')

        // globalStore.commit('LoginModule/signIn')
      } catch (error_) {
        let userMessage = ''

        const { response, error } = error_

        if (response?.status === 409) {
          const responseJSON = await response?.json()

          if (responseJSON.column === 'email') {
            userMessage = 'Email is already in system'
          } else {
            userMessage = 'Data entered already exists for another user'
          }
        } else if (response?.status === 422) {
          const responseJSON: Record<
            'errors',
            Record<string, string>[]
          > = await response?.json()

          userMessage = Object.values(responseJSON.errors)?.[0].message
        } else {
          userMessage = 'Error registering'
        }

        ff.errorHandler({ error, response, userMessage })
      }

      f7.dialog.close()
    }

    const signIn = async () => {
      f7.dialog.preloader()

      try {
        const response = await ff.post('/login', {
          data: state.loginForm,
        })

        const responseJSON = await response.json()

        localStorage.setItem('token', responseJSON.apiToken.token)
        localStorage.setItem('userId', responseJSON.userId)

        if (isNativePlatform) {
          // store.applicationUsername = localStorage.getItem('userId')
        }

        state.loginForm = {
          email: '',
          password: '',
        }

        globalStore.commit('LoginModule/signIn')

        const { router } = f7.views.current

        router.navigate({ name: 'scan-card' }, { animate: false })

        setActiveTab()

        // await globalStore.dispatch('UserModule/getUserInfo')
      } catch (error_) {
        let userMessage = ''

        const { response, error } = error_

        if (response?.status === 404) {
          userMessage = 'User is not found'
        } else if (response?.status === 403) {
          userMessage = await response.text()
          // userMessage = 'Password is incorrect'
        } else if (response?.status === 422) {
          const responseJSON: Record<
            'errors',
            Record<string, string>[]
          > = await response?.json()

          userMessage = Object.values(responseJSON.errors)?.[0].message
        } else {
          userMessage = 'Error logging in'
        }

        ff.errorHandler({ error, response, userMessage })
      }

      f7.dialog.close()
    }

    const resetPassword = () => {
      if (!state.loginForm.email) {
        f7.dialog.alert('No email entered')
        return
      }

      f7.dialog.confirm(
        `Send password reset to ${state.loginForm.email}?`,
        async () => {
          f7.dialog.preloader()

          try {
            await ff.post('/reset-password', {
              data: { email: state.loginForm.email },
            })

            f7.dialog.alert('Password reset link sent to email')
          } catch (error_) {
            let userMessage = ''

            const { response, error } = error_

            if (response?.status === 404) {
              userMessage = 'User is not found'
            } else {
              userMessage = 'Error resetting password'
            }

            ff.errorHandler({ error, response, userMessage })
          }

          f7.dialog.close()
        }
      )
    }

    const resendEmailConfirmation = () => {
      if (!state.loginForm.email) {
        f7.dialog.alert('No email entered')
        return
      }

      f7.dialog.confirm(
        `Resend email confirmation to ${state.loginForm.email}?`,
        async () => {
          f7.dialog.preloader()

          try {
            await ff.post('/resend-email-confirmation', {
              data: { email: state.loginForm.email },
            })

            f7.dialog.alert('Email confirmation resent')
          } catch (error_) {
            let userMessage = ''

            const { response, error } = error_

            if (response?.status === 404) {
              userMessage = 'User is not found'
            } else if (response?.status === 409) {
              userMessage = 'This account has already been confirmed'
            } else {
              userMessage = 'Error resending password'
            }

            ff.errorHandler({ error, response, userMessage })
          }

          f7.dialog.close()
        }
      )
    }

    const redirectToHomeIfLoggedOut = () => {
      const { router } = f7.views.current

      // If not logged and route isn't confirm email or reset password
      if (
        !state.isLoggedIn &&
        router.currentRoute.name !== 'confirm-email' &&
        router.currentRoute.name !== 'reset-password'
      ) {
        router.navigate('/', { animate: false })
      }
    }

    watch(
      () => state.isLoggedIn,
      () => {
        redirectToHomeIfLoggedOut()
      }
    )

    onMounted(() => {
      if (localStorage.getItem('token') && localStorage.getItem('userId')) {
        globalStore.commit('LoginModule/signIn')

        if (isNativePlatform) {
          // store.applicationUsername = localStorage.getItem('userId')
        }
      } else {
        globalStore.commit('LoginModule/signOut')
      }

      f7ready(() => {
        state.routeName = f7.views.current.router.currentRoute.name

        setActiveTab()

        redirectToHomeIfLoggedOut()
        // // Init capacitor APIs (see capacitor-app.js)
        // if (device.capacitor) {
        //   capacitorApp.init(f7)
        // }
        // // Call F7 APIs here
      })
    })

    return {
      ...toRefs(state),
      // routeName: 'reset-password',
      f7params,
      setActiveTab,
      onHomeTabClicked,
      onSavedCardsTabClicked,
      onSettingsTabClicked,
      register,
      signIn,
      resetPassword,
      resendEmailConfirmation,
    }
  },
}
</script>

<style>
/* #login-screen-popup .login-screen-page {
  background-color: #fff;
} */
/* #login-screen-popup .login-screen-page {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(https://image.shutterstock.com/shutterstock/photos/1827688298/display_1500/stock-photo-glen-mills-pa-usa-october-a-collection-of-american-football-trading-cards-1827688298.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
} */

/* .page-content {
  max-width: 500px;
  margin: 0px auto;
} */
</style>
