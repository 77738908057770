/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
import { Capacitor } from '@capacitor/core';
// Cordova In-App Purchases plugin;
const { store } = window;
const { isNativePlatform } = Capacitor;
export default {
    namespaced: true,
    state: {
        isLoggedIn: false,
    },
    mutations: {
        signIn(state) {
            state.isLoggedIn = true;
        },
        signOut(state) {
            state.isLoggedIn = false;
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            if (isNativePlatform()) {
                // store.applicationUsername = ''
            }
        },
    },
};
