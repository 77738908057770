import { defineComponent, onMounted, reactive, ref, toRefs, watch } from 'vue';
import CardImage from '@/components/card-image.vue';
import CardInfo from '@/components/card-info.vue';
import ff from '@/js/far-fetch-init';
export default defineComponent({
    components: {
        CardImage,
        CardInfo,
    },
    props: {
        // cardInfo: {
        //   type: Object,
        //   default: () => ({}),
        // },
        cardId: {
            type: String,
            default: '0',
        },
    },
    emits: ['update:cardInfo'],
    setup(props, { emit }) {
        const cardInfoComponent = ref();
        const state = reactive({
            cardInfo: {
                id: 0,
                imageSrc: { front: '', back: '' },
                frontDefects: [],
                backDefects: [],
                cardNumber: 'N/A',
                cardSerialNumber: 'N/A',
                year: '',
                manufacturerId: 0,
                sportId: '',
                productId: 0,
                parallelId: 0,
                productYearSportId: 0,
                playerName: '',
                cardScore: 0,
            },
        });
        const getCard = async () => {
            try {
                const { responseJSON } = await ff.get(`/cards/${props.cardId}`, {
                    errorMsgNoun: 'card',
                });
                state.cardInfo = responseJSON;
            }
            catch { }
        };
        const updateCard = async () => {
            await cardInfoComponent.value.addCard({
                imageSrc: { front: null, back: null },
                isEdit: true,
            });
        };
        watch(() => state.cardInfo, () => {
            emit('update:cardInfo', state.cardInfo);
        }, { deep: true });
        onMounted(async () => {
            await getCard();
        });
        return { ...toRefs(state), updateCard };
    },
});
