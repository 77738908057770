import { reactive, toRefs, onMounted, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { Capacitor } from '@capacitor/core';
import { f7, f7ready } from 'framework7-vue';
import { getDevice } from 'framework7/lite-bundle';
import ff from '@/js/far-fetch-init';
// import capacitorApp from '../js/capacitor-app.js'
import routes from '../js/routes.js';
const { isNativePlatform } = Capacitor;
// // Cordova In-App Purchases plugin;
// const { store } = window
// import logo from '@/assets/logo.jpg'
export default {
    setup() {
        const globalStore = useStore();
        const device = getDevice();
        // Framework7 Parameters
        const f7params = {
            name: 'PlayerGraders',
            theme: 'auto',
            id: 'com.playergraders.app',
            // App store
            // store,
            // App routes
            routes,
            view: {
                browserHistory: true,
                browserHistoryStoreHistory: true,
                browserHistorySeparator: '',
                // browserHistoryRoot: 'http://localhost:8080/',
                browserHistoryInitialMatch: true,
                browserHistoryOnLoad: false,
                stackPages: true,
            },
            // Input settings
            input: {
                scrollIntoViewOnFocus: device.capacitor,
                scrollIntoViewCentered: device.capacitor,
            },
            // Navbar settings
            navbar: {
                mdCenterTitle: true,
            },
            // Capacitor Statusbar settings
            statusbar: {
                iosOverlaysWebView: true,
                androidOverlaysWebView: false,
            },
            touch: {
                tapHold: true, // enable tap hold events
            },
        };
        const state = reactive({
            // isLoggedIn: false,
            createAccountPopupOpened: false,
            routeName: '',
            isLoggedIn: computed(() => globalStore.state.LoginModule.isLoggedIn),
            loginForm: {
                email: '',
                password: '',
            },
            registerForm: {
                firstName: '',
                lastName: '',
                email: '',
                password: '',
            },
            tabLinkActive: {
                scanCard: false,
                savedCards: false,
                settings: false,
            },
        });
        const setActiveTab = () => {
            const { router } = f7.views.current;
            const routeName = router.currentRoute?.route?.name;
            state.tabLinkActive = {
                scanCard: false,
                savedCards: false,
                settings: false,
            };
            if (routeName === 'saved-cards' || routeName === 'folder-inner') {
                state.tabLinkActive.savedCards = true;
                (document.querySelector('.tab-link-highlight')).style.transform = 'translate3d(100%, 0px, 0px)';
            }
            else if (routeName === 'settings') {
                state.tabLinkActive.settings = true;
                (document.querySelector('.tab-link-highlight')).style.transform = 'translate3d(200%, 0px, 0px)';
                // Everything goes in first tab (home)
            }
            else {
                state.tabLinkActive.scanCard = true;
                (document.querySelector('.tab-link-highlight')).style.transform = 'translate3d(0%, 0px, 0px)';
            }
        };
        const onHomeTabClicked = () => {
            const view = f7.views.get('#view-home');
            // console.log(view.router.currentRoute?.route)
            view.router.navigate({ name: 'scan-card' }, { animate: false });
            view.router.updateCurrentUrl('/');
        };
        const onSavedCardsTabClicked = () => {
            const view = f7.views.get('#view-saved-cards');
            const routeName = view.router.currentRoute?.route?.name;
            if (routeName !== 'folder-inner' || !routeName) {
                view.router.navigate({ name: 'saved-cards' }, { animate: false });
                view.router.updateCurrentUrl('/saved-cards/');
            }
            else if (routeName === 'folder-inner') {
                view.router.updateCurrentUrl(view.router?.currentRoute?.url);
            }
        };
        const onSettingsTabClicked = () => {
            const view = f7.views.get('#view-settings');
            view.router.navigate({ name: 'settings' }, { animate: false });
            view.router.updateCurrentUrl('/settings/');
        };
        const register = async () => {
            f7.dialog.preloader();
            try {
                await ff.post('/register', {
                    data: state.registerForm,
                });
                // const responseJSON = await response.json()
                // localStorage.setItem('token', responseJSON.apiToken.token)
                // localStorage.setItem('userId', responseJSON.userId)
                // if (isNativePlatform) {
                //   store.applicationUsername = localStorage.getItem('userId')
                // }
                state.registerForm = {
                    firstName: '',
                    lastName: '',
                    email: '',
                    password: '',
                };
                state.createAccountPopupOpened = false;
                f7.dialog.alert('Please confirm your account by email');
                // globalStore.commit('LoginModule/signIn')
            }
            catch (error_) {
                let userMessage = '';
                const { response, error } = error_;
                if (response?.status === 409) {
                    const responseJSON = await response?.json();
                    if (responseJSON.column === 'email') {
                        userMessage = 'Email is already in system';
                    }
                    else {
                        userMessage = 'Data entered already exists for another user';
                    }
                }
                else if (response?.status === 422) {
                    const responseJSON = await response?.json();
                    userMessage = Object.values(responseJSON.errors)?.[0].message;
                }
                else {
                    userMessage = 'Error registering';
                }
                ff.errorHandler({ error, response, userMessage });
            }
            f7.dialog.close();
        };
        const signIn = async () => {
            f7.dialog.preloader();
            try {
                const response = await ff.post('/login', {
                    data: state.loginForm,
                });
                const responseJSON = await response.json();
                localStorage.setItem('token', responseJSON.apiToken.token);
                localStorage.setItem('userId', responseJSON.userId);
                if (isNativePlatform) {
                    // store.applicationUsername = localStorage.getItem('userId')
                }
                state.loginForm = {
                    email: '',
                    password: '',
                };
                globalStore.commit('LoginModule/signIn');
                const { router } = f7.views.current;
                router.navigate({ name: 'scan-card' }, { animate: false });
                setActiveTab();
                // await globalStore.dispatch('UserModule/getUserInfo')
            }
            catch (error_) {
                let userMessage = '';
                const { response, error } = error_;
                if (response?.status === 404) {
                    userMessage = 'User is not found';
                }
                else if (response?.status === 403) {
                    userMessage = await response.text();
                    // userMessage = 'Password is incorrect'
                }
                else if (response?.status === 422) {
                    const responseJSON = await response?.json();
                    userMessage = Object.values(responseJSON.errors)?.[0].message;
                }
                else {
                    userMessage = 'Error logging in';
                }
                ff.errorHandler({ error, response, userMessage });
            }
            f7.dialog.close();
        };
        const resetPassword = () => {
            if (!state.loginForm.email) {
                f7.dialog.alert('No email entered');
                return;
            }
            f7.dialog.confirm(`Send password reset to ${state.loginForm.email}?`, async () => {
                f7.dialog.preloader();
                try {
                    await ff.post('/reset-password', {
                        data: { email: state.loginForm.email },
                    });
                    f7.dialog.alert('Password reset link sent to email');
                }
                catch (error_) {
                    let userMessage = '';
                    const { response, error } = error_;
                    if (response?.status === 404) {
                        userMessage = 'User is not found';
                    }
                    else {
                        userMessage = 'Error resetting password';
                    }
                    ff.errorHandler({ error, response, userMessage });
                }
                f7.dialog.close();
            });
        };
        const resendEmailConfirmation = () => {
            if (!state.loginForm.email) {
                f7.dialog.alert('No email entered');
                return;
            }
            f7.dialog.confirm(`Resend email confirmation to ${state.loginForm.email}?`, async () => {
                f7.dialog.preloader();
                try {
                    await ff.post('/resend-email-confirmation', {
                        data: { email: state.loginForm.email },
                    });
                    f7.dialog.alert('Email confirmation resent');
                }
                catch (error_) {
                    let userMessage = '';
                    const { response, error } = error_;
                    if (response?.status === 404) {
                        userMessage = 'User is not found';
                    }
                    else if (response?.status === 409) {
                        userMessage = 'This account has already been confirmed';
                    }
                    else {
                        userMessage = 'Error resending password';
                    }
                    ff.errorHandler({ error, response, userMessage });
                }
                f7.dialog.close();
            });
        };
        const redirectToHomeIfLoggedOut = () => {
            const { router } = f7.views.current;
            // If not logged and route isn't confirm email or reset password
            if (!state.isLoggedIn &&
                router.currentRoute.name !== 'confirm-email' &&
                router.currentRoute.name !== 'reset-password') {
                router.navigate('/', { animate: false });
            }
        };
        watch(() => state.isLoggedIn, () => {
            redirectToHomeIfLoggedOut();
        });
        onMounted(() => {
            if (localStorage.getItem('token') && localStorage.getItem('userId')) {
                globalStore.commit('LoginModule/signIn');
                if (isNativePlatform) {
                    // store.applicationUsername = localStorage.getItem('userId')
                }
            }
            else {
                globalStore.commit('LoginModule/signOut');
            }
            f7ready(() => {
                state.routeName = f7.views.current.router.currentRoute.name;
                setActiveTab();
                redirectToHomeIfLoggedOut();
                // // Init capacitor APIs (see capacitor-app.js)
                // if (device.capacitor) {
                //   capacitorApp.init(f7)
                // }
                // // Call F7 APIs here
            });
        });
        return {
            ...toRefs(state),
            // routeName: 'reset-password',
            f7params,
            setActiveTab,
            onHomeTabClicked,
            onSavedCardsTabClicked,
            onSettingsTabClicked,
            register,
            signIn,
            resetPassword,
            resendEmailConfirmation,
        };
    },
};
