import { computed, defineComponent, onMounted, reactive, toRefs, watch, } from 'vue';
import { f7 } from 'framework7-vue';
import { Capacitor } from '@capacitor/core';
import ff from '@/js/far-fetch-init';
import { useStore } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import kebabCase from 'lodash.kebabcase';
import Navbar from '@/components/navbar.vue';
const { isNativePlatform } = Capacitor;
export default defineComponent({
    components: { Navbar },
    setup() {
        // const searchBar = ref()
        const globalStore = useStore();
        const state = reactive({
            searchText: '',
            folderActionSheetOpeend: false,
            editViewShown: false,
            currentFolderIdSelected: 0,
            // folders: { default: { total: 0 }, folders: { data: [], meta: [] } },
            folders: computed(() => globalStore.state.FolderModule.folders),
            uniqueId: uuidv4(),
        });
        const getFolders = async () => {
            try {
                const { responseJSON } = await ff.get('/folders', {
                    errorMsgNoun: 'folders',
                });
                state.folders = responseJSON;
            }
            catch { }
        };
        const addFolder = () => {
            f7.dialog.prompt('Folder Name', async (folderName) => {
                try {
                    const { responseJSON } = await ff.post('/folders', {
                        data: { name: folderName, slug: kebabCase(folderName) },
                        // errorMsgNoun: 'folders',
                    });
                    state.folders.folders.data.unshift(responseJSON);
                }
                catch (error_) {
                    let userMessage = '';
                    const { response, error } = error_;
                    if (response?.status === 422) {
                        const responseJSON = await response?.json();
                        userMessage = Object.values(responseJSON.errors)?.[0].message;
                    }
                    else {
                        userMessage = 'Error adding folder';
                    }
                    ff.errorHandler({ error, response, userMessage });
                }
            });
        };
        const updateFolder = (id) => {
            f7.dialog.prompt('Folder Name', async (folderName) => {
                try {
                    await ff.patch(`/folders/${id}`, {
                        data: { name: folderName, slug: kebabCase(folderName) },
                        // errorMsgNoun: 'folder',
                    });
                    const folderIndex = state.folders.folders.data.findIndex(({ id: folderId }) => folderId === id);
                    state.folders.folders.data[folderIndex].name = folderName;
                    // state.folders.folders.data.unshift(responseJSON)
                }
                catch (error_) {
                    let userMessage = '';
                    const { response, error } = error_;
                    if (response?.status === 422) {
                        const responseJSON = await response?.json();
                        userMessage = Object.values(responseJSON.errors)?.[0].message;
                    }
                    else {
                        userMessage = 'Error updating folder';
                    }
                    ff.errorHandler({ error, response, userMessage });
                }
            });
        };
        const deleteFolder = () => {
            f7.dialog.confirm('Are you sure you want to delete folder?', async () => {
                try {
                    await ff.delete(`/folders/${state.currentFolderIdSelected}`, {
                        errorMsgNoun: 'folder',
                    });
                    state.folders.folders.data = state.folders.folders.data.filter(({ id }) => id !== state.currentFolderIdSelected);
                    f7.dialog.alert('Folders are deleted', () => {
                        const { router } = f7.views.current;
                        router.navigate({ name: 'trash-inner' });
                    });
                    // state.folders.folders.data.unshift(responseJSON)
                }
                catch { }
            });
        };
        const onSearchOpen = () => {
            const searchBar = f7.searchbar.get(`#search-bar-${state.uniqueId}`);
            searchBar.inputEl.focus();
            searchBar.search(state.searchText).enable();
        };
        const onSearch = async () => {
            try {
                const { responseJSON } = await ff.get('/folders', {
                    data: {
                        filters: JSON.stringify({ name: state.searchText }),
                    },
                    errorMsgNoun: 'folders',
                });
                globalStore.commit('FolderModule/setFolders', responseJSON);
                // state.folders = responseJSON
            }
            catch { }
        };
        const showEditView = () => {
            if (state.folders.folders.data.length === 0) {
                f7.dialog.alert('No custom folders added.');
                return;
            }
            state.editViewShown = true;
        };
        const closeEditOptions = () => {
            state.editViewShown = false;
            state.currentFolderIdSelected = 0;
        };
        watch(() => state.searchText, async () => {
            await onSearch();
        });
        onMounted(async () => {
            console.log('saved cards mounted');
            await globalStore.dispatch('FolderModule/getFolders');
            console.log(state.folders);
            // await getFolders()
        });
        return {
            ...toRefs(state),
            kebabCase,
            isNativePlatform,
            onSearchOpen,
            onSearch,
            showEditView,
            closeEditOptions,
            getFolders,
            addFolder,
            updateFolder,
            deleteFolder,
        };
    },
});
