<template>
  <f7-list media-list>
    <f7-list-item
      title="Default Folder"
      :after="`${folders.default?.total}`"
      link="#"
      @click="$emit('click:folder', 0)"
    >
      <template #media>
        <img src="@/assets/folder-icon.png" style="width: 56px; height: 56px" />

        <!-- <f7-icon size="56px" f7="folder_fill" /> -->
      </template>
    </f7-list-item>
  </f7-list>

  <f7-list media-list>
    <f7-list-item
      v-for="{ id, name, total } in folders.folders?.data"
      :key="id"
      :title="name"
      :after="`${total}`"
      link="#"
      @click="$emit('click:folder', id)"
    >
      <template #media>
        <img src="@/assets/folder-icon.png" style="width: 56px; height: 56px" />
      </template>
    </f7-list-item>
  </f7-list>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

// import cloneDeep from 'lodash.clonedeep'

import { Folders } from '@/js/types'

export default defineComponent({
  props: {
    folders: {
      type: Object as PropType<Folders>,
      default: () => ({}),
    },
  },
  emits: ['update:folders', 'click:folder'],
  // setup(props, { emit }) {
  //   const state = reactive({
  //     foldersLocal: cloneDeep(props.folders),
  //   })

  //   watch(
  //     () => state.foldersLocal,
  //     () => {
  //       emit('update:folders', state.foldersLocal)
  //     },
  //     { deep: true }
  //   )

  //   watch(
  //     () => props.folders,
  //     () => {
  //       state.foldersLocal = cloneDeep(props.folders)
  //     },
  //     { deep: true }
  //   )

  //   return { ...toRefs(state) }
  // },
})
</script>
