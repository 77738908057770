<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <navbar />
    <f7-page-content>
      <!-- Take Photo Popup
    <f7-popup
      v-model:opened="takePhotoPopupOpened"
      @popup:opened="startCamera('front')"
      @popup:close="CameraPreview.stop()"
    >
      <f7-page>
        <f7-page-content class="flex items-end">
          <div class="flex w-full">
            <f7-link class="flex-1 mb-10" @click="takePhotoPopupOpened = false"
              >Cancel</f7-link
            >

            <f7-link
              icon-f7="camera_circle_fill"
              icon-size="64px"
              icon-color="red"
              class="flex-1 mb-10"
              @click="takePhoto()"
            />

            <div class="flex-1 mb-10" />
          </div>
        </f7-page-content>
      </f7-page>
    </f7-popup> -->

      <!-- Add Info Popup -->
      <f7-popup
        v-if="isNativePlatform()"
        v-model:opened="addInfoPopupOpened"
        swipe-to-close
      >
        <f7-page>
          <f7-navbar>
            <f7-nav-left></f7-nav-left>
            <f7-nav-title>Add Info</f7-nav-title>
            <f7-nav-right>
              <f7-link @click="addInfoPopupOpened = false">Close</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <card-info v-model:card-info="cardInfo" />

          <f7-block>
            <f7-button @click="addCard()">Add card</f7-button>
          </f7-block>
        </f7-page>
      </f7-popup>

      <!-- Grade Card Popup -->
      <f7-popup
        v-if="isNativePlatform()"
        v-model:opened="gradeCardPopupOpened"
        swipe-to-close
      >
        <f7-page>
          <f7-navbar>
            <f7-nav-left></f7-nav-left>
            <f7-nav-title>Card Grade</f7-nav-title>
            <f7-nav-right>
              <f7-link @click="gradeCardPopupOpened = false">Close</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <f7-block class="flex flex-col items-center">
            <p style="font-size: 50px" class="mb-4 mt-4 text-center">
              Playergraders score is
            </p>

            <p
              style="font-size: 65px; font-weight: bold"
              class="text-color-red"
            >
              <span class="mr-6">PG</span>{{ recommendation }}
            </p>

            <f7-button
              fill
              large
              class="my-6"
              @click="addInfoPopupOpened = true"
              >Edit Card Info</f7-button
            >

            <img
              style="width: 240px; height: 336px"
              class="mx-auto"
              :src="imageSrc.front"
            />
          </f7-block>
        </f7-page>
      </f7-popup>

      <!-- Page content for mobile -->
      <template v-if="isNativePlatform()">
        <f7-block strong inset class="flex justify-between">
          <f7-button
            fill
            raised
            class="flex-1 mr-6"
            @click="addInfoPopupOpened = true"
          >
            <f7-icon f7="text_badge_plus" size="16px" class="mr-2" />
            Add Info
          </f7-button>

          <f7-button fill raised class="flex-1" @click="gradeCard()">
            <f7-icon f7="checkmark_circle_fill" size="16px" class="mr-2" />
            Grade Card
          </f7-button>
        </f7-block>

        <f7-block strong inset style="text-align: center">
          <!-- Card Swiper -->
          <f7-swiper pagination navigation effect="flip" :loop="true">
            <f7-swiper-slide>
              <img
                style="width: 240px; height: 336px"
                class="mx-auto"
                :src="imageSrc.front"
              />
            </f7-swiper-slide>
            <f7-swiper-slide>
              <img
                style="width: 240px; height: 336px"
                class="mx-auto"
                :src="imageSrc.back"
              />
            </f7-swiper-slide>
          </f7-swiper>
        </f7-block>
      </template>

      <!-- Page content for web -->
      <template v-else>
        <div class="flex mx-auto" style="width: 1100px">
          <!-- <div class="flex flex-col">
          <f7-block v-for="side in ['front', 'back']" :key="side" strong inset>
            <h2 class="capitalize font-bold text-3xl text-center mb-6">
              {{ side }}
            </h2>

            <div
              class="relative flex items-center cursor-pointer"
              @click="startCamera(side)"
            >
              <img :ref="`image${startCase(side)}`" :src="imageSrc[side]" />

              <div v-if="cardInfo[side]">
                <div
                  v-for="(detection, index) in cardInfo[side].defects"
                  :key="index"
                  class="absolute w-full border border-red-600"
                  :style="`
                    left: ${detection.xmin / imageProportion[side].width}px; 
                    top: ${detection.ymin / imageProportion[side].height}px; 
                    right: ${detection.xmax / imageProportion[side].width}px; 
                    bottom: ${detection.ymax / imageProportion[side].height}px;
                    width: ${
                      detection.xmax / imageProportion[side].width -
                      detection.xmin / imageProportion[side].width
                    }px;
                    height: ${
                      detection.ymax / imageProportion[side].height -
                      detection.ymin / imageProportion[side].height
                    }px;
                  `"
                />
              </div>
            </div>
          </f7-block>
        </div> -->

          <card-image
            :image-src="imageSrc.front"
            :defects="defects.front"
            side="front"
            class="flex-1"
            @update:imageSrc="imageSrc.front = $event"
          />

          <div>
            <f7-block
              strong
              inset
              class="text-center"
              style="margin-left: 0px; margin-right: 0px"
            >
              <h2 class="font-bold text-2xl mb-6">Recommendation</h2>

              <div class="text-3xl mb-12 inline-block">
                <!-- <p class="text-color-primary">Coming Soon</p> -->
                <p v-if="recommendation === null" class="text-color-primary">
                  {{ 'N/A' }}
                </p>

                <div
                  v-else-if="recommendation === 'yes'"
                  class="text-color-green flex items-center"
                >
                  <f7-icon f7="checkmark_circle_fill" class="mr-2" />
                  <p>Send In</p>
                </div>

                <div
                  v-else-if="recommendation === 'maybe'"
                  class="text-color-gray flex items-center"
                >
                  <f7-icon f7="question_circle_fill" class="mr-2" />
                  <a>Maybe Send In</a>
                </div>

                <div
                  v-else-if="recommendation === 'no'"
                  class="text-color-primary flex items-center"
                >
                  <f7-icon f7="xmark_circle_fill" class="mr-2" />
                  <a>Don't Send In</a>
                </div>
              </div>

              <h2 class="font-bold text-2xl mb-6">Total Defects</h2>

              <p class="text-3xl text-color-primary">
                {{ defects.front.length + defects.back.length ?? 'N/A' }}
              </p>
            </f7-block>

            <f7-block strong inset style="margin-left: 0px; margin-right: 0px">
              <f7-button fill raised class="flex-1" @click="getFolders()">
                <f7-icon f7="checkmark_circle_fill" size="16px" class="mr-2" />
                Analyze Card
              </f7-button>

              <f7-button
                fill
                raised
                href="/"
                :reload-current="true"
                class="flex-1 bg-color-gray mt-4"
              >
                <f7-icon
                  f7="arrow_clockwise_circle_fill"
                  size="16px"
                  class="mr-2"
                />
                Clear All
              </f7-button>
            </f7-block>
          </div>

          <card-image
            :image-src="imageSrc.back"
            :defects="defects.back"
            side="back"
            class="flex-1"
            @update:imageSrc="imageSrc.back = $event"
          />

          <!-- <f7-block strong inset class="col-start-3 col-end-5">
          <h2 class="font-bold text-3xl text-center">Card Info</h2>

          <card-info ref="cardInfoComponent" v-model:card-info="cardInfo" />

          <f7-block>
            <f7-button @click="addCard()">Add card</f7-button>
          </f7-block>
        </f7-block> -->
        </div>
      </template>

      <!-- <f7-block strong class="flex justify-between">
      <f7-button class="flex-grow mr-6" fill raised @click="takePhoto('front')"
        ><f7-icon f7="camera_fill" size="16px" class="mr-2" />Capture
        Front</f7-button
      >
      <f7-button class="flex-grow" fill raised @click="takePhoto('back')"
        ><f7-icon f7="camera_fill" size="16px" class="mr-2" />Capture
        Back</f7-button
      >
    </f7-block> -->

      <f7-popup v-model:opened="chooseFolderPopupOpened">
        <f7-page>
          <f7-navbar title="Select Folder">
            <f7-nav-right>
              <!-- <f7-link popup-close>Close</f7-link> -->
            </f7-nav-right>
          </f7-navbar>

          <folder-list
            :folders="folders"
            @click:folder="gradeCard({ folderId: $event })"
          />
        </f7-page>
      </f7-popup>

      <f7-popup v-model:opened="imageQualitySizeTestPopupOpened">
        <f7-page>
          <f7-block class="mt-10">
            <h2>
              Image size:
              {{ imageQualitySizeTest.imageSizeInKilobytes }} kilobyes | width:
              {{ imageQualitySizeTest.width }} | height:
              {{ imageQualitySizeTest.height }}. Change to...
            </h2>
          </f7-block>

          <f7-list>
            <!-- <f7-list-input
            id="imageSize"
            v-model:value="imageQualitySizeTest.changedSizeInKilobytes"
            type="text"
            floating-label
            label="Size in (kb)"
          /> -->
            <f7-list-input
              id="imageWidth"
              v-model:value="imageQualitySizeTest.aspectRatioWidth"
              type="text"
              floating-label
              label="Width"
            />
            <f7-list-input
              id="imageHeight"
              v-model:value="imageQualitySizeTest.aspectRatioHeight"
              type="text"
              floating-label
              label="Height"
            />
          </f7-list>

          <f7-block>
            <button @click="compressImage()">Compress Image</button>
          </f7-block>
        </f7-page>
      </f7-popup>
    </f7-page-content>
  </f7-page>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  // ref,
  toRefs,
  // watch,
} from 'vue'

import { useStore } from 'vuex'

import CardInfo from '@/components/card-info.vue'
import CardImage from '@/components/card-image.vue'
import Navbar from '@/components/navbar.vue'
import FolderList from '@/components/folder-list.vue'

import { f7 } from 'framework7-vue'

import {
  // Plugins,
  // CameraResultType,
  // StatusBarStyle,
  // CameraSource,
  Capacitor,
  // FilesystemDirectory,
} from '@capacitor/core'

import { Camera, CameraSource, CameraResultType } from '@capacitor/camera'

import { StatusBar, Style as StatusBarStyle } from '@capacitor/status-bar'

import ff from '@/js/far-fetch-init'

// import { registerIAP, setupListenersIAP } from '@/js/iap'

// import camelCase from 'lodash.camelcase'
import startCase from 'lodash.startcase'

// import { CameraPreviewOptions } from '@capacitor-community/camera-preview'

import { compressAccurately } from 'image-conversion'

import defaultNoImage from '@/assets/default-no-image.svg'
// import backgroundImage from '@/assets/background.png'

import { Folders } from '@/js/types'

declare global {
  interface Window {
    store: any
    VisionKit: any
  }
}

// Cordova In-App Purchases plugin;
const { store, VisionKit } = window

export const subscriptionsProductList = {
  ios: [
    'com.playergraders.app.tier_1_monthly',
    'com.playergraders.app.tier_2_monthly',
    'com.playergraders.app.tier_3_monthly',
    'com.playergraders.app.tier_4_monthly',
  ],
  // android: [
  //   'com.tradecheq.app.monthly_gold_subscription',
  //   'com.tradecheq.app.yearly_gold_subscription',
  // ],
}

const { isNativePlatform, convertFileSrc } = Capacitor

// const { Camera, StatusBar } = Plugins

// interface ImageSrc {
//   front: string
//   back: string
// }

type CardPhotoSide = 'front' | 'back'

// const imageSrc: ImageSrc = {
//   front: 'https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg',
//   back: 'https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg',
//   // front: '',
//   // back: '',
// }

let currentCameraPhotoSide: CardPhotoSide

let folders: Folders

export default defineComponent({
  components: {
    CardInfo,
    CardImage,
    Navbar,
    FolderList,
  },
  setup() {
    const cardInfoComponent = ref<InstanceType<typeof CardInfo>>()

    // const imageFront = ref<HTMLImageElement>()
    // const imageBack = ref<HTMLImageElement>()
    // const cardSwiper = ref()

    const globalStore = useStore()

    const state = reactive({
      imageSrc: {
        front: defaultNoImage,
        back: defaultNoImage,
      },
      // imageProportion: {
      //   front: {
      //     width: computed(() => {
      //       return imageFront.value.naturalWidth / imageFront.value.clientWidth
      //     }),
      //     height: computed(() => {
      //       return (
      //         imageFront.value.naturalHeight / imageFront.value.clientHeight
      //       )
      //     }),
      //   },
      //   back: {
      //     width: computed(() => {
      //       return imageBack.value.naturalWidth / imageBack.value.clientWidth
      //     }),
      //     height: computed(() => {
      //       return imageBack.value.naturalHeight / imageBack.value.clientHeight
      //     }),
      //   },
      // },
      addInfoPopupOpened: false,
      gradeCardPopupOpened: false,
      takePhotoPopupOpened: false,
      currentCameraPhotoSide,
      tags: [],
      cardInfo: {
        cardNumber: 'N/A',
        cardSerialNumber: 'N/A',
        year: '',
        manufacturerId: 0,
        sportId: '',
        productId: 0,
        parallelId: 0,
        productYearSportId: 0,
        playerName: '',
      },
      defects: {
        front: [],
        back: [],
      },
      recommendation: null,
      folders,
      chooseFolderPopupOpened: false,
      imageQualitySizeTest: {
        aspectRatioWidth: 0,
        aspectRatioHeight: 0,
        changedSizeInKilobytes: 0,
        width: 0,
        height: 0,
        imageSizeInKilobytes: 0,
        imageBlob: null,
        type: '',
      },
      imageQualitySizeTestPopupOpened: false,
    })

    const onStartCameraClick = (side: CardPhotoSide) => {
      // cardSwiper.value.slideTo(1)

      state.takePhotoPopupOpened = true

      state.currentCameraPhotoSide = side
    }

    const scanImage = (): Promise<string> => {
      return new Promise((resolve) => {
        VisionKit.scan((images: string[]) => {
          if (images.length > 0) {
            resolve(convertFileSrc(images[0]))

            // state.imageSrc.front = convertFileSrc(images[0])

            // state.imageSrc.back = convertFileSrc(images[1])
          }
        })
      })
    }

    const getImage = async (
      type: CameraSource.Photos | CameraSource.Camera
    ) => {
      const { webPath } = await Camera.getPhoto({
        quality: 100,
        source: type,
        resultType: CameraResultType.Uri,
        webUseInput: true,
      })

      return webPath
    }

    const calculateAspectRatioFit = ({
      srcWidth,
      srcHeight,
      maxWidth,
      maxHeight,
    }) => {
      const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)

      return {
        aspectRatioWidth: srcWidth * ratio,
        aspectRatioHeight: srcHeight * ratio,
      }
    }

    const getImageDimensions = (
      imgSrc
    ): Promise<{ width: number; height: number }> => {
      return new Promise((resolve) => {
        const img = new Image()

        img.src = imgSrc

        img.onload = () => {
          const width = img.naturalWidth
          const height = img.naturalHeight

          resolve({ width, height })
        }
      })
    }

    const compressImage = async () => {
      // const imageSizeInput = state.imageQualitySizeTest.changedSizeInKilobytes
      const imageWidthInput = state.imageQualitySizeTest.aspectRatioWidth
      const imageHeightInput = state.imageQualitySizeTest.aspectRatioHeight

      // console.log(imageSizeInput)
      console.log(imageWidthInput)
      console.log(imageHeightInput)

      const compressedImage = await compressAccurately(
        state.imageQualitySizeTest.imageBlob,
        {
          // size: imageSizeInput,
          width: imageWidthInput,
          height: imageHeightInput,
        }
      )

      state.imageSrc[state.imageQualitySizeTest.type] = URL.createObjectURL(
        compressedImage
      )

      state.imageQualitySizeTestPopupOpened = false

      // console.log(state.imageQualitySizeTest.changedSizeInKilobytes)
    }

    const showPopupManuallyCompressImages = async (type) => {
      f7.dialog.confirm(
        'Would you like to change image size and quality?',
        async () => {
          const imageBlob = await fetch(state.imageSrc[type]).then((r) =>
            r.blob()
          )

          console.log(imageBlob)

          state.imageQualitySizeTest.imageBlob = imageBlob

          state.imageQualitySizeTest.type = type

          const imageSizeInKilobytes = imageBlob.size / 1000

          console.log(imageSizeInKilobytes)

          state.imageQualitySizeTest.imageSizeInKilobytes = imageSizeInKilobytes

          const { width, height } = await getImageDimensions(
            state.imageSrc[type]
          )

          state.imageQualitySizeTest.width = width
          state.imageQualitySizeTest.height = height

          const {
            aspectRatioHeight,
            aspectRatioWidth,
          } = calculateAspectRatioFit({
            srcWidth: width,
            srcHeight: height,
            maxWidth: 1080,
            maxHeight: 1460,
          })

          state.imageQualitySizeTest.aspectRatioHeight = Math.round(
            aspectRatioHeight
          )
          state.imageQualitySizeTest.aspectRatioWidth = Math.round(
            aspectRatioWidth
          )

          state.imageQualitySizeTestPopupOpened = true

          // f7.popup
          //   .create({
          //     content: `

          //     `,
          //   })
          //   .open()
        }
      )
    }

    const resizeImage = async (type: CardPhotoSide) => {
      const imageBlob = await fetch(state.imageSrc[type]).then((r) => r.blob())

      const { width, height } = await getImageDimensions(state.imageSrc[type])

      const compressedImage = await compressAccurately(imageBlob, {
        width,
        height,
      })

      state.imageSrc[type] = URL.createObjectURL(compressedImage)
    }

    const startCamera = async (type: CardPhotoSide) => {
      // const image = await Camera.getPhoto({
      //   quality: 90,
      //   source: CameraSource.Camera,
      //   // allowEditing: true,
      //   resultType: CameraResultType.Uri,
      // })

      // const imageURL = image.webPath

      // // Can be set to the src of an image now
      // state.imageSrc[type] = imageURL

      if (isNativePlatform()) {
        f7.actions
          .create({
            buttons: [
              {
                text: 'Scan Photo',
                // icon: '<f7-icon f7="camera_viewfinder" size="16px" />',
                bold: true,
                onClick: async () => {
                  const image = await scanImage()

                  state.imageSrc[type] = image

                  // await showPopupManuallyCompressImages(type)

                  await resizeImage(type)
                },
              },
              {
                text: 'Photo from Camera',
                // icon: '<f7-icon f7="camera_fill" size="16px" />',
                bold: true,
                onClick: async () => {
                  const image = await getImage(CameraSource.Camera)

                  state.imageSrc[type] = image

                  // await showPopupManuallyCompressImages(type)

                  await resizeImage(type)
                },
              },
              {
                text: 'Photo from Library',
                // icon: '<f7-icon f7="photo" size="16px" />',
                bold: true,
                onClick: async () => {
                  const image = await getImage(CameraSource.Photos)

                  state.imageSrc[type] = image

                  // await showPopupManuallyCompressImages(type)

                  await resizeImage(type)
                },
              },
              {
                text: 'Cancel',
                color: 'gray',
              },
            ],
          })
          .open()
      } else {
        const image = await getImage(CameraSource.Photos)

        state.imageSrc[type] = image

        await resizeImage(type)
      }

      // const rect = document
      //   .querySelector('#take-photo-popup')
      //   .getBoundingClientRect()

      // const cx = rect.left + rect.width * 0.5 // find center of first image
      // const cy = rect.top + rect.height * 0.5

      // const x = Math.round(window.innerWidth / 6)
      // const y = Math.round(window.innerHeight / 4)

      // const cameraPreviewOptions: CameraPreviewOptions = {
      //   position: 'rear',
      //   disableAudio: true,
      //   width: 240,
      //   height: 336,
      //   // storeToFile: true,
      //   x,
      //   y,
      // }

      // CameraPreview.start(cameraPreviewOptions)
    }

    const takePhoto = async () => {
      // const { value: imageFile } = await CameraPreview.capture({
      //   width: 240,
      //   height: 336,
      //   quality: 100,
      // })
      // // const base64PictureData = result.value
      // state.imageSrc[
      //   state.currentCameraPhotoSide
      // ] = `data:image/png;base64,${imageFile}`
      // // console.log(imageFile)
      // state.takePhotoPopupOpened = false
    }

    const addTag = () => {
      f7.dialog.prompt('Tag Name', (tag) => {
        state.tags.push(tag)
      })
    }

    // const getManufacturers = async () => {
    //   try {
    //     const { responseJSON } = await ff.get('/manufacturers')

    //     state.manufacturers = responseJSON

    //     console.log(state.cardInfo.manufacturer)
    //   } catch {}
    // }

    // const getProductYearsSports = async () => {
    //   try {
    //     const { responseJSON } = await ff.get(
    //       `/products/${state.cardInfo.product.id}/year-sport/`
    //     )

    //     state.productYearsSports = responseJSON

    //     state.productSports = state.productSportsFull
    //     state.productYears = state.productYearsFull

    //     debugger

    //     state.cardInfo.sport = 0
    //     state.cardInfo.manufacturedYear = 0
    //     state.cardInfo.parallel.id = 0

    //     state.parallels = []
    //   } catch {}
    // }

    // const getProducts = async () => {
    //   try {
    //     const { responseJSON } = await ff.get(
    //       `/products/manufacturers/${state.cardInfo.manufacturer.id}`
    //     )

    //     state.products = responseJSON

    //     // await getProductYearsSports()
    //   } catch {}
    // }

    // const getParallels = async () => {
    //   const { sport, manufacturedYear, manufacturer, product } = state.cardInfo

    //   if (sport && manufacturedYear && manufacturer.id && product.id) {
    //     const { id: productYearSportId } =
    //       state.productYearsSports.find((productYearSport) => {
    //         return (
    //           productYearSport.sport === sport &&
    //           productYearSport.year === manufacturedYear
    //         )
    //       }) ?? {}

    //     try {
    //       const {
    //         responseJSON,
    //       } = await ff.get(
    //         `/parallels/product-year-sport/${productYearSportId}`,
    //         { errorMsgNoun: 'parallels' }
    //       )

    //       state.parallels = responseJSON
    //     } catch {}
    //   }
    // }

    const getFolders = async () => {
      if (state.imageSrc.front === defaultNoImage) {
        f7.dialog.alert('Front of card is missing')
        return
      }

      if (state.imageSrc.back === defaultNoImage) {
        f7.dialog.alert('Back of card is missing')
        return
      }

      try {
        const { responseJSON } = await ff.get('/folders', {
          errorMsgNoun: 'folders',
        })

        state.folders = responseJSON

        state.chooseFolderPopupOpened = true
      } catch {}
    }

    const gradeCard = async ({ folderId = 0 }: { folderId?: number } = {}) => {
      // if (state.imageSrc.front === defaultNoImage) {
      //   f7.dialog.alert('Front of card is missing')
      //   return
      // }

      // if (state.imageSrc.back === defaultNoImage) {
      //   f7.dialog.alert('Back of card is missing')
      //   return
      // }

      // await getFolders()

      // state.chooseFolderPopupOpened = true

      state.chooseFolderPopupOpened = false

      let cardName = ''

      const prompt = (): Promise<string> => {
        return new Promise((resolve) => {
          f7.dialog.prompt(
            'Would you like to enter a card name?',
            (inputValue) => {
              resolve(inputValue)
            },
            () => {
              resolve('')
            }
          )
        })
      }

      cardName = await prompt()

      f7.dialog.preloader('Analyzing card')

      // Necessary trick in order to upload files
      const blobFront = await fetch(state.imageSrc.front).then((r) => r.blob())
      const blobBack = await fetch(state.imageSrc.back).then((r) => r.blob())

      try {
        const { responseJSON } = await ff.post('/card-ocr', {
          data: { cardName, folderId },
          files: [blobFront, blobBack],
          // errorMsgNoun: 'grading card',
        })

        state.imageSrc.front = `data:image/jpeg;charset=utf-8;base64,${responseJSON.croppedImages.front}`
        state.imageSrc.back = `data:image/jpeg;charset=utf-8;base64,${responseJSON.croppedImages.back}`

        state.defects = responseJSON.defects

        state.recommendation = responseJSON.recommendation

        // state.gradeCardPopupOpened = true
      } catch (error_) {
        let userMessage = ''

        const { response, error } = error_

        if (response?.status === 413) {
          userMessage = await response?.text()
        } else if (response?.status === 422) {
          const responseJSON: Record<
            'errors',
            Record<string, string>[]
          > = await response?.json()

          userMessage = Object.values(responseJSON.errors)?.[0].message

          // const isExtname = responseJSON.errors.find(
          //   ({ rule }) => rule === 'file.extname'
          // )

          // if (isExtname !== undefined) {
          //   userMessage = isExtname.message
          // } else {
          //   userMessage = Object.values(responseJSON.errors)?.[0].message
          // }
        } else {
          userMessage = 'Error checking defects'
        }

        ff.errorHandler({ error, response, userMessage })
      }

      f7.dialog.close()
    }

    const switchToSavedCardsTab = () => {
      f7.tab.show('#view-saved-cards')
    }

    const resetCardInfo = () => {
      state.cardInfo = {
        cardNumber: 'N/A',
        cardSerialNumber: 'N/A',
        year: '',
        manufacturerId: 0,
        sportId: '',
        productId: 0,
        parallelId: 0,
        productYearSportId: 0,
        playerName: '',
      }

      state.recommendation = 0
    }

    const addCard = async () => {
      await cardInfoComponent.value.addCard({ imageSrc: state.imageSrc })

      await globalStore.dispatch('FolderModule/getFolders')

      f7.dialog.alert('Card successfully added')

      const { router } = f7.views.current

      router.navigate({ name: 'saved-cards' }, { animate: false })

      // f7.dialog.preloader('Adding card')

      // const {
      //   playerName,
      //   parallel: { id: parallelId },
      //   productYearSportId,
      //   cardNumber,
      //   cardSerialNumber,
      // } = state.cardInfo

      // // const { id: productYearSportId } = state.productYearsSports.find(
      // //   (productYearSport) => {
      // //     return (
      // //       productYearSport.sport === state.cardInfo.sport &&
      // //       productYearSport.year === state.cardInfo.manufacturedYear
      // //     )
      // //   }
      // // )

      // const blobFront = await fetch(state.imageSrc.front).then((r) => r.blob())
      // const blobBack = await fetch(state.imageSrc.back).then((r) => r.blob())

      // // Set to toString() to prevent typescript error
      // const data = {
      //   playerName,
      //   productYearSportId: productYearSportId
      //     ? productYearSportId.toString()
      //     : null,
      //   parallelId: parallelId ? parallelId.toString() : null,
      //   rating: state.cardScore?.toString(),
      //   cardNumber,
      //   cardSerialNumber,
      // }

      // const queryString = `${new URLSearchParams(data)}`

      // try {
      //   await ff.post(`/cards?${queryString}`, {
      //     files: [blobFront, blobBack],
      //     errorMsgNoun: 'card',
      //   })

      //   f7.preloader.hide()

      //   await globalStore.dispatch('FolderModule/getFolders')

      //   state.addInfoPopupOpened = false
      //   state.gradeCardPopupOpened = false

      //   f7.dialog.alert('Card successfully added')

      //   resetCardInfo()

      //   const { router } = f7.views.current

      //   router.navigate({ name: 'saved-cards' }, { animate: false })

      //   // switchToSavedCardsTab()
      // } catch {}

      // f7.dialog.close()
    }

    // const setSmartSelectHTMLFormat = () => {
    //   const valueElement = document.querySelector('#select-product .item-after')

    //   valueElement.classList.remove('item-after')
    //   valueElement.classList.add('item-subtitle')
    // }

    // const onAddInfoPopupOpen = async () => {
    //   if (state.manufacturers.length === 0) {
    //     await getManufacturers()
    //   }
    // }

    // const registerIAP = () => {
    //   store.register([
    //     {
    //       id: subscriptionsProductList[f7.device.os][0],
    //       type: store.PAID_SUBSCRIPTION,
    //     },
    //     {
    //       id: subscriptionsProductList[f7.device.os][1],
    //       type: store.PAID_SUBSCRIPTION,
    //     },
    //   ])

    //   store.validator =
    //     'https://validator.fovea.cc/v1/validate?appName=playergraders&apiKey=cd98562b-84c6-4fbd-9616-168bef1bf8c5'

    //   debugger
    // }

    // const setupListenersIAP = () => {
    //   store.error(() => {
    //     f7.preloader.hide()

    //     // f7.dialog.alert('Error purchasing item');
    //   })

    //   // Called when any subscription product is updated
    //   store
    //     .when('subscription')
    //     // .requested(() => {
    //     //   // // Do this to account for Offer Codes on iOS. Is redundant in other cases
    //     //   // f7.preloader.show()
    //     // })
    //     .approved((p) => p.verify())
    //     .verified((p) => p.finish())
    //     .owned(async () => {
    //       // try {
    //       //   const { responseJSON } = await ff.patch(
    //       //     '/subscriptions/update-and-check',
    //       //     {
    //       //       errorMsg: 'Error subscribing',
    //       //     }
    //       //   )
    //       //   globalStore.commit('UserModule/setUserInfo', {
    //       //     ...state.userInfo,
    //       //     ...responseJSON,
    //       //   })
    //       // } catch {}
    //     })

    //   store.refresh()

    //   store.ready(() => {
    //     // state.subscriptions.push(
    //     //   store.get(subscriptionsProductList[f7.device.os][0])
    //     // )
    //     // state.subscriptions.push(
    //     //   store.get(subscriptionsProductList[f7.device.os][1])
    //     // )
    //   })
    // }

    // watch(
    //   () => state.cardInfo.sport,
    //   async () => {
    //     if (state.cardInfo.sport) {
    //       // Make sure year exists in sport
    //       const productYearsSportsAvailable = state.productYearsSports.filter(
    //         (productYearSport) => {
    //           return productYearSport.sport === state.cardInfo.sport
    //         }
    //       )

    //       state.productYears = productYearsSportsAvailable.map(
    //         ({ year }) => year
    //       )

    //       await getParallels()
    //     }
    //   }
    // )

    // watch(
    //   () => state.cardInfo.manufacturedYear,
    //   async () => {
    //     if (state.cardInfo.manufacturedYear) {
    //       // Make sure sport exists in year
    //       const productYearsSportsAvailable = state.productYearsSports.filter(
    //         (productYearSport) => {
    //           return productYearSport.year === state.cardInfo.manufacturedYear
    //         }
    //       )

    //       state.productSports = productYearsSportsAvailable.map(
    //         ({ sport }) => sport
    //       )

    //       await getParallels()
    //     }
    //   }
    // )

    onMounted(() => {
      console.log('home is mounted')

      // setTimeout(() => {
      //   state.cardInfo.playerName = 'test'
      // }, 3000)

      // registerIAP()
      // setupListenersIAP()

      setTimeout(() => {
        if (isNativePlatform()) {
          console.log('is native')

          // registerIAP()
          // setupListenersIAP()

          StatusBar.setStyle({
            style: StatusBarStyle.Dark,
          })
        }
      })
    })

    return {
      ...toRefs(state),
      isNativePlatform,
      // backgroundImage,
      startCase,
      cardInfoComponent,
      // imageFront,
      // imageBack,
      onStartCameraClick,
      startCamera,
      takePhoto,
      addTag,
      getFolders,
      gradeCard,
      // setSmartSelectHTMLFormat,
      // getProducts,
      addCard,
      // getProductYearsSports,
      // onAddInfoPopupOpen,
      // CameraPreview,
      compressImage,
    }
  },
})
</script>
