import { defineComponent, onMounted, reactive, ref, toRefs, watch } from 'vue';
import { Capacitor } from '@capacitor/core';
// import { f7 } from 'framework7-vue'
import ff from '@/js/far-fetch-init';
import { f7 } from 'framework7-vue';
import startCase from 'lodash.startcase';
import kebabCase from 'lodash.kebabcase';
import { v4 as uuidv4 } from 'uuid';
import CardImage from '@/components/card-image.vue';
import CardInfo from '@/components/card-info.vue';
const { isNativePlatform } = Capacitor;
export default defineComponent({
    components: {
        CardImage,
        CardInfo,
    },
    props: {
        // folderId: {
        //   type: String,
        //   default: '0',
        // },
        folderNameSlug: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const cardInfoComponent = ref();
        const cardImageComponentFront = ref();
        const cardImageComponentBack = ref();
        const state = reactive({
            searchText: '',
            cardPopupOpened: false,
            cardPopupIsClicked: false,
            cards: { data: [], meta: {} },
            folders: [],
            cardInfo: {
                id: 0,
                imageSrc: { front: '', back: '' },
                frontDefects: [],
                backDefects: [],
                cardName: '',
                cardNumber: 'N/A',
                cardSerialNumber: 'N/A',
                year: '',
                manufacturerId: 0,
                sportId: '',
                productId: 0,
                parallelId: 0,
                productYearSportId: 0,
                playerName: '',
                cardScore: 0,
            },
            cardInfoActionSheetOpened: false,
            chooseFolderMovePopupOpened: false,
            checkedCardIds: [],
            // folderName: '',
            folderId: 0,
            uniqueId: uuidv4(),
            // chooseFolderCardId: 0,
        });
        const getCardsInFolder = async () => {
            try {
                const { responseJSON } = await ff.get(`/folders/${props.folderNameSlug}`, {
                    errorMsgNoun: 'cards',
                });
                state.cards = responseJSON;
                state.folderId = responseJSON.meta.folderId;
                // state.folderName = parseInt(props.folderId, 10)
                //   ? responseJSON.meta.folderName
                //   : 'Default Folder'
            }
            catch { }
        };
        const deleteCards = async () => {
            f7.dialog.confirm('Are you sure you want to delete this card?', async () => {
                try {
                    await ff.delete('/cards', {
                        errorMsgNoun: 'cards',
                        data: { cardIds: state.checkedCardIds },
                    });
                    state.cards.data = state.cards.data.filter(({ id }) => !state.checkedCardIds.includes(id));
                    state.checkedCardIds = [];
                    f7.dialog.alert('Cards are deleted', () => {
                        const { router } = f7.views.current;
                        router.navigate({ name: 'trash-inner' });
                    });
                }
                catch { }
            });
        };
        const openCardPopup = async ({ cardId, event, }) => {
            if (state.cardPopupIsClicked)
                return;
            state.cardPopupIsClicked = true;
            f7.dialog.preloader('Processing image');
            try {
                const { responseJSON } = await ff.get(`/cards/${cardId}`, {
                    errorMsgNoun: 'card',
                });
                state.cardInfo = responseJSON;
                // state.cardInfo.image = image
                state.cardPopupOpened = true;
            }
            catch { }
        };
        const getFolders = async () => {
            try {
                const { responseJSON } = await ff.get('/folders', {
                    errorMsgNoun: 'folders',
                });
                state.folders = responseJSON;
            }
            catch { }
        };
        const openCardInfoActionSheet = ({ cardId }) => {
            state.checkedCardIds = [];
            state.checkedCardIds.push(cardId);
            state.cardInfoActionSheetOpened = true;
        };
        const chooseFolderMove = async () => {
            await getFolders();
            state.chooseFolderMovePopupOpened = true;
        };
        const moveCardToNewFolder = async ({ folderNameSlug, folderId, }) => {
            try {
                await ff.patch(`/cards`, {
                    data: { folderId, cardIds: state.checkedCardIds },
                    errorMsgNoun: 'moving card',
                });
                state.chooseFolderMovePopupOpened = false;
                state.cards.data = state.cards.data.filter(({ id }) => !state.checkedCardIds.includes(id));
                state.checkedCardIds = [];
                f7.dialog.alert('Cards are moved to new folder', () => {
                    const { router } = f7.views.current;
                    router.navigate(`/folder-inner/${folderNameSlug}`);
                });
            }
            catch { }
        };
        const onSearchOpen = () => {
            const searchBar = f7.searchbar.get(`#search-bar-cards-${state.uniqueId}`);
            searchBar.inputEl.focus();
            searchBar.search(state.searchText).enable();
        };
        const onSearch = async () => {
            try {
                const { responseJSON } = await ff.get(`/folders/${props.folderNameSlug}`, {
                    data: {
                        filters: { playerName: state.searchText },
                    },
                    errorMsgNoun: 'cards',
                });
                state.cards = responseJSON;
            }
            catch { }
        };
        const onCheckChange = (event) => {
            const { checked, value } = event.target;
            const valueFormatted = parseInt(value, 10);
            if (checked) {
                state.checkedCardIds.push(valueFormatted);
            }
            else {
                state.checkedCardIds.splice(state.checkedCardIds.indexOf(valueFormatted), 1);
            }
        };
        const updateCard = async () => {
            await cardInfoComponent.value.addCard({
                imageSrc: { front: null, back: null },
                isEdit: true,
            });
        };
        const onUpdateCardSuccess = () => {
            const cardIndex = state.cards.data.findIndex(({ id }) => id === state.cardInfo.id);
            state.cards.data[cardIndex].cardName = state.cardInfo.cardName;
            f7.dialog.alert('Card updated successfully');
        };
        const onCardPopupOpen = () => {
            cardImageComponentFront.value.resetCroppedImageLoaded();
            cardImageComponentBack.value.resetCroppedImageLoaded();
        };
        const onCardPopupClose = () => {
            state.cardInfo.imageSrc.front = '';
            state.cardInfo.imageSrc.back = '';
            state.cardInfo.frontDefects = [];
            state.cardInfo.backDefects = [];
            state.cardPopupIsClicked = false;
            f7.dialog.close();
        };
        watch(() => state.searchText, async () => {
            await onSearch();
        });
        onMounted(async () => {
            await getCardsInFolder();
        });
        return {
            ...toRefs(state),
            cardInfoComponent,
            cardImageComponentFront,
            cardImageComponentBack,
            startCase,
            kebabCase,
            isNativePlatform,
            getCardsInFolder,
            deleteCards,
            openCardPopup,
            openCardInfoActionSheet,
            chooseFolderMove,
            moveCardToNewFolder,
            onSearchOpen,
            onSearch,
            onCheckChange,
            updateCard,
            onUpdateCardSuccess,
            onCardPopupOpen,
            onCardPopupClose,
        };
    },
});
