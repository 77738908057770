<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <!-- <f7-link>Edit</f7-link> -->
      </f7-nav-left>
      <f7-nav-title>Card Info</f7-nav-title>
      <f7-nav-right>
        <f7-link>Close</f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-block class="flex flex-col items-center" style="width: 405px">
      <f7-button @click="updateCard()">Update</f7-button>

      <card-info
        ref="cardInfoComponent"
        v-model:card-info="cardInfo"
        class="w-full"
      />

      <card-image
        :image-src="cardInfo.imageSrc.front"
        :defects="cardInfo.frontDefects"
        side="front"
        @update:imageSrc="cardInfo.imageSrc.front = $event"
      />

      <card-image
        :image-src="cardInfo.imageSrc.back"
        :defects="cardInfo.backDefects"
        side="back"
        @update:imageSrc="cardInfo.imageSrc.back = $event"
      />
    </f7-block>
  </f7-page>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, toRefs, watch } from 'vue'

import CardImage from '@/components/card-image.vue'
import CardInfo from '@/components/card-info.vue'

import ff from '@/js/far-fetch-init'

export default defineComponent({
  components: {
    CardImage,
    CardInfo,
  },
  props: {
    // cardInfo: {
    //   type: Object,
    //   default: () => ({}),
    // },
    cardId: {
      type: String,
      default: '0',
    },
  },
  emits: ['update:cardInfo'],
  setup(props, { emit }) {
    const cardInfoComponent = ref<InstanceType<typeof CardInfo>>()

    const state = reactive({
      cardInfo: {
        id: 0,
        imageSrc: { front: '', back: '' },
        frontDefects: [],
        backDefects: [],
        cardNumber: 'N/A',
        cardSerialNumber: 'N/A',
        year: '',
        manufacturerId: 0,
        sportId: '',
        productId: 0,
        parallelId: 0,
        productYearSportId: 0,
        playerName: '',
        cardScore: 0,
      },
    })

    const getCard = async () => {
      try {
        const { responseJSON } = await ff.get(`/cards/${props.cardId}`, {
          errorMsgNoun: 'card',
        })

        state.cardInfo = responseJSON
      } catch {}
    }

    const updateCard = async () => {
      await cardInfoComponent.value.addCard({
        imageSrc: { front: null, back: null },
        isEdit: true,
      })
    }

    watch(
      () => state.cardInfo,
      () => {
        emit('update:cardInfo', state.cardInfo)
      },
      { deep: true }
    )

    onMounted(async () => {
      await getCard()
    })

    return { ...toRefs(state), updateCard }
  },
})
</script>
