<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link text="Back" href="/saved-cards/" icon-f7="chevron_left" />

        <!-- If card or folder selected -->
        <template
          v-if="
            checkedItems.cardIds.length > 0 || checkedItems.folderIds.length > 0
          "
        >
          <f7-link @click="restoreItems()">Restore</f7-link>

          <f7-link @click="deleteItems()">Delete</f7-link>
        </template>
      </f7-nav-left>
      <f7-nav-title>Trash</f7-nav-title>
      <f7-nav-right></f7-nav-right>

      <!-- <f7-searchbar
        id="search-bar"
        v-model:value="searchText"
        expandable
        custom-search
      /> -->
    </f7-navbar>

    <f7-block strong>
      <f7-treeview>
        <!-- <f7-treeview-item label="Item 1">
          <f7-treeview-item label="Sub Item 1">
            <f7-treeview-item label="Sub Sub Item 1"></f7-treeview-item>
            <f7-treeview-item label="Sub Sub Item 2"></f7-treeview-item>
          </f7-treeview-item>
          <f7-treeview-item label="Sub Item 2">
            <f7-treeview-item label="Sub Sub Item 1"></f7-treeview-item>
            <f7-treeview-item label="Sub Sub Item 2"></f7-treeview-item>
          </f7-treeview-item>
        </f7-treeview-item> -->
        <!-- <f7-treeview-item
          v-for="folder in trashItems.folders"
          :key="folder.id"
          :label="folder.cardGrading.cardName"
        ></f7-treeview-item> -->

        <f7-treeview-item
          v-for="folder in trashItems.folders"
          :key="folder.id"
          :label="folder.name"
          icon-f7="folder_fill"
        >
          <template #content-start>
            <f7-checkbox
              :checked="
                checkedItems.folderIds.includes(folder.id) && !isIndeterminate
              "
              :indeterminate="isIndeterminate"
              :value="folder.id"
              @change="onCheckChange({ event: $event, type: 'folderIds' })"
            />
          </template>

          <f7-treeview-item
            v-for="card in folder.cards"
            :key="card.id"
            :label="card.cardName"
          >
            <template #media>
              <img :src="card.imageSrc" style="width: auto; height: 24px" />
            </template>

            <template #content-start>
              <f7-checkbox
                :checked="checkedItems.cardIds.includes(card.id)"
                :value="card.id"
                @change="
                  onCheckChange({
                    event: $event,
                    folderId: folder.id,
                    type: 'cardIds',
                  })
                "
              />
            </template>
          </f7-treeview-item>
        </f7-treeview-item>

        <f7-treeview-item
          v-for="card in trashItems.cards"
          :key="card.id"
          :label="card.cardName"
        >
          <template #media>
            <img :src="card.imageSrc" style="width: auto; height: 24px" />
          </template>

          <template #content-start>
            <f7-checkbox
              :checked="checkedItems.cardIds.includes(card.id)"
              :value="card.id"
              @change="
                onCheckChange({
                  event: $event,
                  type: 'cardIds',
                })
              "
            />
          </template>
        </f7-treeview-item>
      </f7-treeview>
    </f7-block>
  </f7-page>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs } from 'vue'

import ff from '@/js/far-fetch-init'

export default defineComponent({
  setup() {
    const state = reactive({
      trashItems: { cards: [], folders: [] },
      checkedItems: { cardIds: [], folderIds: [] },
      isIndeterminate: false,
      editViewShown: false,
    })

    const getTrashItems = async () => {
      try {
        const { responseJSON } = await ff.get('/trash', {
          errorMsgNoun: 'trash items',
        })

        state.trashItems = responseJSON
      } catch {}
    }

    const restoreItems = async () => {
      try {
        await ff.patch('/trash', {
          errorMsgNoun: 'trash items',
          data: { items: state.checkedItems },
        })

        await getTrashItems()
      } catch {}
    }

    const deleteItems = async () => {
      try {
        await ff.delete('/trash', {
          errorMsgNoun: 'trash items',
          data: { items: state.checkedItems },
        })

        await getTrashItems()
      } catch {}
    }

    const onCheckChange = ({
      event,
      folderId = 0,
      type,
    }: {
      event: Event
      folderId?: number
      type: 'folderIds' | 'cardIds'
    }) => {
      const { checked, value } = <HTMLInputElement>event.target

      const valueFormatted = parseInt(value, 10)

      let folderIdFormatted
      let folderObject
      let cardIdsInFolder: number[]

      if (folderId || type === 'folderIds') {
        // Use folderId variable if card
        folderIdFormatted = type === 'folderIds' ? valueFormatted : folderId

        // Folder object by id
        folderObject = state.trashItems.folders.find(
          ({ id }) => id === folderIdFormatted
        )

        cardIdsInFolder = folderObject.cards.map(({ id }) => id)
      }

      if (checked) {
        state.checkedItems[type].push(valueFormatted)

        if (type === 'folderIds') {
          state.checkedItems.cardIds.push(...cardIdsInFolder)
        } else if (type === 'cardIds' && folderId) {
          const allCardsInFolderChecked = state.checkedItems.cardIds.every(
            (id) => cardIdsInFolder.includes(id)
          )

          // Set folder to checked if all cards checked
          if (allCardsInFolderChecked) {
            state.checkedItems.folderIds.push(folderIdFormatted)
          }
        }
      } else {
        state.checkedItems[type].splice(
          state.checkedItems[type].indexOf(valueFormatted),
          1
        )

        if (type === 'folderIds') {
          state.checkedItems.cardIds = state.checkedItems.cardIds.filter(
            (id) => !cardIdsInFolder.includes(id)
          )
        } else if (type === 'cardIds' && folderId) {
          const anyCardInFolderChecked = state.checkedItems.cardIds.some((id) =>
            cardIdsInFolder.includes(id)
          )

          // Set folder to unchecked if all cards unchecked
          if (!anyCardInFolderChecked) {
            state.checkedItems.folderIds.splice(
              state.checkedItems.folderIds.indexOf(folderId),
              1
            )
          }
        }
      }

      if (type === 'folderIds') {
        const allCardsInFolderChecked = cardIdsInFolder.every((id) =>
          state.checkedItems.cardIds.includes(id)
        )

        const someCardsInFolderChecked = cardIdsInFolder.some((id) =>
          state.checkedItems.cardIds.includes(id)
        )

        // If not all cards checked, but some are
        if (!allCardsInFolderChecked && someCardsInFolderChecked) {
          state.isIndeterminate = true
        } else {
          state.isIndeterminate = false
        }
      }
    }

    onMounted(async () => {
      await getTrashItems()
    })

    return { ...toRefs(state), restoreItems, deleteItems, onCheckChange }
  },
})
</script>
