import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, toRefs, } from 'vue';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
// type mgShape = 'circle' | 'square'
// interface Props {
//   // Image
//   src: string
//   width: string | number
//   height: string | number
//   className: string
//   // Zoom image
//   zoomImgSrc: string
//   zoomFactor: number
//   // Magnifying glass
//   mgWidth: number
//   mgHeight: number
//   mgBorderWidth: number
//   mgShape: mgShape
//   mgShowOverflow: boolean
//   mgMouseOffsetX: number
//   mgMouseOffsetY: number
//   mgTouchOffsetX: number
//   mgTouchOffsetY: number
// }
// interface State {
//   showZoom: boolean
//   // Magnifying glass offset
//   mgOffsetX: number
//   mgOffsetY: number
//   // Mouse position relative to image
//   relX: number
//   relY: number
// }
export default defineComponent({
    inheritAttrs: false,
    props: {
        // Image
        src: {
            type: String,
            default: '',
        },
        width: {
            type: [String, Number],
            default: '100%',
        },
        height: {
            type: [String, Number],
            default: 'auto',
        },
        className: {
            type: String,
            default: '',
        },
        // Zoom image
        zoomImgSrc: {
            type: String,
            default: '',
        },
        zoomFactor: {
            type: Number,
            default: 1.5,
        },
        // Magnifying glass
        mgWidth: {
            type: Number,
            default: 150,
        },
        mgHeight: {
            type: Number,
            default: 150,
        },
        mgBorderWidth: {
            type: Number,
            default: 2,
        },
        mgShape: {
            type: String,
            default: 'circle',
        },
        mgShowOverflow: {
            type: Boolean,
            default: true,
        },
        mgMouseOffsetX: {
            type: Number,
            default: 0,
        },
        mgMouseOffsetY: {
            type: Number,
            default: 0,
        },
        mgTouchOffsetX: {
            type: Number,
            default: -50,
        },
        mgTouchOffsetY: {
            type: Number,
            default: -50,
        },
        // Custom prop to show or hide magnifying glass
        mgShow: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['image:load'],
    setup(props, { emit }) {
        const img = ref(null);
        const state = reactive({
            imgBounds: null,
            showZoom: false,
            mgOffsetX: 0,
            mgOffsetY: 0,
            relX: 0,
            relY: 0,
            mgClasses: computed(() => {
                let classes = 'magnifying-glass';
                if (state.showZoom)
                    classes += ' visible';
                if (props.mgShape === 'circle')
                    classes += ' circle';
                return classes;
            }),
            // styleObject: {
            //   width: props.mgWidth,
            //   height: props.mgHeight,
            //   left: `calc(${state.relX * 100}% - ${props.mgWidth / 2}px + ${mgOffsetX}px - ${mgBorderWidth}px)`,
            //   top: `calc(${relY * 100}% - ${mgHeight / 2}px + ${mgOffsetY}px - ${mgBorderWidth}px)`,
            //   backgroundImage: `url("${zoomImgSrc || src}")`,
            //   backgroundPosition: `calc(${relX * 100}% + ${mgWidth / 2}px - ${relX *
            //     mgWidth}px) calc(${relY * 100}% + ${mgHeight / 2}px - ${relY * mgWidth}px)`,
            //   backgroundSize: `${zoomFactor * imgBounds.width}% ${zoomFactor *
            //     imgBounds.height}%`,
            //   borderWidth: mgBorderWidth,
            // }
        });
        const calcImgBounds = () => {
            if (img.value) {
                state.imgBounds = img.value.getBoundingClientRect();
            }
        };
        const onImageLoad = (event) => {
            emit('image:load', event);
            calcImgBounds();
        };
        const onMouseEnter = () => {
            calcImgBounds();
        };
        let onMouseMove = (e) => {
            const { mgMouseOffsetX, mgMouseOffsetY } = props;
            if (state.imgBounds) {
                const target = e.target;
                const relX = (e.clientX - state.imgBounds.left) / target.clientWidth;
                const relY = (e.clientY - state.imgBounds.top) / target.clientHeight;
                state.mgOffsetX = mgMouseOffsetX;
                state.mgOffsetY = mgMouseOffsetY;
                state.relX = relX;
                state.relY = relY;
                state.showZoom = true;
            }
        };
        const onMouseOut = () => {
            state.showZoom = false;
        };
        const onTouchStart = (e) => {
            e.preventDefault(); // Prevent mouse event from being fired
            calcImgBounds();
        };
        let onTouchMove = (e) => {
            e.preventDefault(); // Disable scroll on touch
            if (state.imgBounds) {
                const target = e.target;
                const { mgTouchOffsetX, mgTouchOffsetY } = props;
                const relX = (e.targetTouches[0].clientX - state.imgBounds.left) /
                    target.clientWidth;
                const relY = (e.targetTouches[0].clientY - state.imgBounds.top) /
                    target.clientHeight;
                // Only show magnifying glass if touch is inside image
                if (relX >= 0 && relY >= 0 && relX <= 1 && relY <= 1) {
                    state.mgOffsetX = mgTouchOffsetX;
                    state.mgOffsetY = mgTouchOffsetY;
                    state.relX = relX;
                    state.relY = relY;
                    state.showZoom = true;
                }
                else {
                    state.showZoom = false;
                }
            }
        };
        const onTouchEnd = () => {
            state.showZoom = false;
        };
        const calcImgBoundsDebounced = debounce(calcImgBounds, 200);
        onMouseMove = throttle(onMouseMove, 20, { trailing: false });
        onTouchMove = throttle(onTouchMove, 20, { trailing: false });
        const registerEventListeners = () => {
            // // Add mouse/touch event listeners to image element (assigned in render function)
            // // `passive: false` prevents scrolling on touch move
            // img.value.addEventListener('mouseenter', onMouseEnter, {
            //   passive: false,
            // })
            // img.value.addEventListener('mousemove', onMouseMove, {
            //   passive: false,
            // })
            // img.value.addEventListener('mouseout', onMouseOut, { passive: false })
            // img.value.addEventListener('touchstart', onTouchStart, {
            //   passive: false,
            // })
            // img.value.addEventListener('touchmove', onTouchMove, {
            //   passive: false,
            // })
            // img.value.addEventListener('touchend', onTouchEnd, { passive: false })
            // Re-calculate image bounds on window resize
            window.addEventListener('resize', calcImgBoundsDebounced);
            // Re-calculate image bounds on scroll (useCapture: catch scroll events in entire DOM)
            window.addEventListener('scroll', calcImgBoundsDebounced, true);
        };
        const unRegisterEventListeners = () => {
            // // Remove all event listeners
            // img.value.removeEventListener('mouseenter', onMouseEnter)
            // img.value.removeEventListener('mousemove', onMouseMove)
            // img.value.removeEventListener('mouseout', onMouseOut)
            // img.value.removeEventListener('touchstart', onTouchStart)
            // img.value.removeEventListener('touchmove', onTouchMove)
            // img.value.removeEventListener('touchend', onTouchEnd)
            window.removeEventListener('resize', calcImgBoundsDebounced);
            window.removeEventListener('scroll', calcImgBoundsDebounced, true);
        };
        onMounted(() => {
            registerEventListeners();
        });
        onUnmounted(() => {
            unRegisterEventListeners();
        });
        return {
            ...toRefs(state),
            img,
            onImageLoad,
            onMouseEnter,
            onMouseMove,
            onMouseOut,
            onTouchStart,
            onTouchMove,
            onTouchEnd,
        };
    },
});
