<template>
  <!-- Side Panel for responsive -->
  <f7-panel v-model:opened="sidePanelIsOpened">
    <f7-list menu-list>
      <f7-list-item
        link="/"
        :reload-current="activeLink === 'scan-card'"
        title="Scan Card"
        :selected="activeLink === 'scan-card'"
        @click="sidePanelIsOpened = false"
      >
        <template #media>
          <f7-icon f7="viewfinder" />
        </template>
      </f7-list-item>

      <f7-list-item
        link="/saved-cards/"
        :reload-current="
          activeLink === 'saved-cards' ||
          activeLink === 'folder-inner' ||
          activeLink === 'trash-inner'
        "
        title="Saved Cards"
        :selected="
          activeLink === 'saved-cards' ||
          activeLink === 'folder-inner' ||
          activeLink === 'trash-inner'
        "
        @click="sidePanelIsOpened = false"
      >
        <template #media>
          <f7-icon f7="folder_fill" />
        </template>
      </f7-list-item>

      <f7-list-item
        link="/settings/"
        :reload-current="activeLink === 'settings'"
        title="Settings"
        :selected="activeLink === 'settings'"
        @click="sidePanelIsOpened = false"
      >
        <template #media>
          <f7-icon f7="gear" />
        </template>
      </f7-list-item>
    </f7-list>
  </f7-panel>

  <!-- Top Navbar -->
  <f7-navbar>
    <f7-nav-left class="mx-5">
      <f7-link href="/" :reload-current="activeLink === 'scan-card'">
        <img
          style="height: auto; width: 285px"
          class="mx-auto"
          src="@/assets/logo-same-line.png"
        />
      </f7-link>
    </f7-nav-left>
    <f7-nav-title style="display: none">
      <slot name="center"></slot>
    </f7-nav-title>
    <f7-nav-right class="mx-5">
      <!-- Full sized navbar -->
      <template v-if="fullSizedNavbar">
        <f7-link
          href="/"
          :reload-current="activeLink === 'scan-card'"
          :class="{ 'active-navbar-link': activeLink === 'scan-card' }"
          ><f7-icon f7="viewfinder" class="mr-2" />Scan Card</f7-link
        >
        <f7-link
          href="/saved-cards/"
          :reload-current="
            activeLink === 'saved-cards' ||
            activeLink === 'folder-inner' ||
            activeLink === 'trash-inner'
          "
          :class="{
            'active-navbar-link':
              activeLink === 'saved-cards' ||
              activeLink === 'folder-inner' ||
              activeLink === 'trash-inner',
          }"
          ><f7-icon f7="folder_fill" class="mr-2" />Saved Cards</f7-link
        >
        <f7-link
          href="/settings/"
          :reload-current="activeLink === 'settings'"
          :class="{ 'active-navbar-link': activeLink === 'settings' }"
          ><f7-icon f7="gear" class="mr-2" />Settings</f7-link
        >
      </template>

      <template v-else>
        <f7-link icon-f7="menu" @click="sidePanelIsOpened = true" />
      </template>
    </f7-nav-right>
  </f7-navbar>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
} from 'vue'

import { f7, f7ready } from 'framework7-vue'

// type ActiveLink = 'scan-card' | 'saved-cards' | 'folder-inner'

export default defineComponent({
  setup() {
    const state = reactive({
      activeLink: '',
      sidePanelIsOpened: false,
      fullSizedNavbar: true,
      // window,
      // fullSizedNavbar: computed(() => {
      //   return window.matchMedia('(min-width: 1100px)').matches
      // }),
    })

    const setActiveNavbarLink = () => {
      const { router } = f7.views.main

      const routeName = router.currentRoute?.route?.name

      state.activeLink = routeName
    }

    const checkWidth = () => {
      if (window.matchMedia('(min-width: 965px)').matches) {
        state.fullSizedNavbar = true
      } else {
        state.fullSizedNavbar = false
      }
    }

    onMounted(() => {
      checkWidth()
      window.addEventListener('resize', checkWidth)

      f7ready(() => {
        setTimeout(() => {
          setActiveNavbarLink()
        })
      })
    })

    onUnmounted(() => {
      window.removeEventListener('resize', checkWidth)
    })

    return { ...toRefs(state) }
  },
})
</script>
