// import { createApp } from 'vue'
import { createStore } from 'vuex';
import LoginModule from '@/store/login';
import FolderModule from '@/store/folder';
const store = createStore({
    modules: {
        LoginModule,
        FolderModule,
    },
});
export default store;
