import { defineComponent, onMounted, onUnmounted, reactive, toRefs, } from 'vue';
import { f7, f7ready } from 'framework7-vue';
// type ActiveLink = 'scan-card' | 'saved-cards' | 'folder-inner'
export default defineComponent({
    setup() {
        const state = reactive({
            activeLink: '',
            sidePanelIsOpened: false,
            fullSizedNavbar: true,
            // window,
            // fullSizedNavbar: computed(() => {
            //   return window.matchMedia('(min-width: 1100px)').matches
            // }),
        });
        const setActiveNavbarLink = () => {
            const { router } = f7.views.main;
            const routeName = router.currentRoute?.route?.name;
            state.activeLink = routeName;
        };
        const checkWidth = () => {
            if (window.matchMedia('(min-width: 965px)').matches) {
                state.fullSizedNavbar = true;
            }
            else {
                state.fullSizedNavbar = false;
            }
        };
        onMounted(() => {
            checkWidth();
            window.addEventListener('resize', checkWidth);
            f7ready(() => {
                setTimeout(() => {
                    setActiveNavbarLink();
                });
            });
        });
        onUnmounted(() => {
            window.removeEventListener('resize', checkWidth);
        });
        return { ...toRefs(state) };
    },
});
