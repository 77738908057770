/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ff from '@/js/far-fetch-init';
export default {
    namespaced: true,
    state: {
        folders: {
            default: { total: 0 },
            trash: { total: 0 },
            folders: { data: [], meta: [] },
        },
    },
    mutations: {
        setFolders(state, payload) {
            state.folders = payload;
        },
    },
    actions: {
        async getFolders({ commit }) {
            try {
                const { responseJSON } = await ff.get('/folders', {
                    errorMsgNoun: 'folders',
                });
                commit('setFolders', responseJSON);
            }
            catch { }
        },
    },
};
