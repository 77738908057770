<template>
  <f7-list inset>
    <f7-list-input
      v-model:value="cardInfoLocal.cardName"
      type="text"
      label="Card Name"
      placeholder="Enter Card Name"
    />

    <!-- <f7-list-input
      v-model:value="cardInfoLocal.playerName"
      type="text"
      label="Player Name"
      placeholder="Enter Player Name"
    />

    <f7-list-input
      v-model:value="cardInfoLocal.manufacturerId"
      type="select"
      label="Manufacturer"
      placeholder="Enter Manufacturer Name"
    >
      <option disabled selected value="0"></option>

      <option v-for="{ id, name } in manufacturers" :key="id" :value="id">
        {{ name }}
      </option>
    </f7-list-input>

    <f7-list-item
      :id="`select-product-${uniqueId}`"
      smart-select
      :smart-select-params="{
        openIn: 'popup',
        searchbar: true,
        closeOnSelect: true,
        popupSwipeToClose: true,
        pageTitle: 'Products',
      }"
      title="Product"
      @click="clickInfoDependent($event, 'product')"
    >
      <select
        v-model="cardInfoLocal.productId"
        @change="getProductYearsSports()"
      >
        <option disabled selected value="0"></option>

        <option v-for="{ id, name } in products" :key="id" :value="id">
          {{ name }}
        </option>
      </select>
    </f7-list-item>

    <f7-list-input
      v-model:value="cardInfoLocal.year"
      type="select"
      label="Year"
      placeholder="Enter year"
      @click="clickInfoDependent($event, 'year')"
    >
      <option disabled selected value=""></option>

      <option v-for="year in productYearsFull" :key="year" :value="year">
        {{ year }}
      </option>
    </f7-list-input>

    <f7-list-input
      v-model:value.number="cardInfoLocal.sportId"
      type="select"
      label="Sport"
      placeholder="Enter sport"
      @click="clickInfoDependent($event, 'sport')"
    >
      <option disabled selected value="0"></option>

      <option v-for="{ id, sport } in productSportsFull" :key="id" :value="id">
        {{ sport }}
      </option>
    </f7-list-input>

    <f7-list-item
      :id="`select-parallel-${uniqueId}`"
      smart-select
      :smart-select-params="{
        openIn: 'popup',
        searchbar: true,
        closeOnSelect: true,
        popupSwipeToClose: true,
        pageTitle: 'Parallels',
      }"
      title="Parallel"
      @click="clickInfoDependent($event, 'parallel')"
    >
      <select v-model="cardInfoLocal.parallelId">
        <option disabled selected value="0"></option>

        <option v-for="{ id, name } in parallels" :key="id" :value="id">
          {{ name }}
        </option>
      </select>
    </f7-list-item>

    <f7-list-input
      v-model:value="cardInfoLocal.cardNumber"
      type="text"
      label="Card Number"
      placeholder="Enter Card Number"
    />

    <f7-list-input
      v-model:value="cardInfoLocal.cardSerialNumber"
      type="text"
      label="Card Serial Number"
      placeholder="Enter Card Serial Number"
    /> -->
  </f7-list>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
} from 'vue'

import { v4 as uuidv4 } from 'uuid'

import ff from '@/js/far-fetch-init'

import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'

import { f7, f7ready } from 'framework7-vue'
import { useStore } from 'vuex'

interface ImageSrc {
  front: string
  back: string
}

const cardInfo = {
  id: 0,
  image: { front: '', back: '' },
  cardName: '',
  cardNumber: 'N/A',
  cardSerialNumber: 'N/A',
  year: '',
  manufacturerId: 0,
  sportId: '',
  productId: 0,
  parallelId: 0,
  productYearSportId: 0,
  playerName: '',
}

export default defineComponent({
  props: {
    cardInfo: {
      type: Object,
      default: () => cardInfo,
    },
  },
  emits: ['update:cardInfo', 'updateSuccess'],
  setup(props, { emit }) {
    // const selectSport = ref(null)

    const globalStore = useStore()

    const state = reactive({
      uniqueId: uuidv4(),
      cardInfoLocal: cloneDeep(props.cardInfo),
      cardInfoDefault: {},
      manufacturers: [],
      products: [],
      productYearsSports: [],
      productSports: [],
      productYears: [],
      productSportsFull: computed(() => {
        // return [
        //   ...new Set(
        //     state.productYearsSports.map(({ sport, sportId }) => ({
        //       id: sportId,
        //       sport,
        //     }))
        //   ),
        // ].sort((a, b) => a.sport - b.sport)

        const filtered = state.productYearsSports.reduce(
          (accumulator, { sport, sportId }) => {
            if (!accumulator.find(({ id }) => id === sportId)) {
              accumulator.push({
                id: sportId,
                sport,
              })
            }

            return accumulator
          },
          []
        )

        return filtered
      }),
      productYearsFull: computed(() => {
        return [
          ...new Set(state.productYearsSports.map(({ year }) => year)),
        ].sort()
      }),
      parallels: [],
      needsToLoadParallels: false,
    })

    const getManufacturers = async () => {
      try {
        const { responseJSON } = await ff.get('/manufacturers')

        state.manufacturers = responseJSON

        // state.cardInfoLocal.product.id = 0
        // f7.smartSelect
        //   .get(`#select-product-${state.uniqueId} .smart-select`)
        //   .setValue('')
      } catch {}
    }

    const getProducts = async () => {
      try {
        const { responseJSON } = await ff.get(
          `/products/manufacturers/${state.cardInfoLocal.manufacturerId}`
        )

        state.products = responseJSON

        // await getProductYearsSports()
      } catch {}
    }

    const getProductYearSportIdSelected = async () => {
      const { id: productYearSportId } = state.productYearsSports.find(
        (productYearSport) => {
          return (
            productYearSport.sportId === state.cardInfoLocal.sportId &&
            productYearSport.year === state.cardInfoLocal.year
          )
        }
      )

      state.cardInfoLocal.productYearSportId = productYearSportId
    }

    const getParallels = async () => {
      const { sportId, year, manufacturerId, productId } = state.cardInfoLocal

      if (sportId && year && manufacturerId && productId) {
        const { id: productYearSportId } =
          state.productYearsSports.find((productYearSport) => {
            return (
              productYearSport.sportId === sportId &&
              productYearSport.year === year
            )
          }) ?? {}

        try {
          const {
            responseJSON,
          } = await ff.get(
            `/parallels/product-year-sport/${productYearSportId}`,
            { errorMsgNoun: 'parallels' }
          )

          state.parallels = responseJSON
        } catch {}
      }
    }

    const getProductYearsSports = async () => {
      if (state.cardInfoLocal.productId) {
        try {
          const { responseJSON } = await ff.get(
            `/products/${state.cardInfoLocal.productId}/year-sport/`
          )

          state.productYearsSports = responseJSON

          state.productSports = state.productSportsFull
          state.productYears = state.productYearsFull

          if (state.needsToLoadParallels) {
            await getProductYearSportIdSelected()

            await getParallels()

            f7.smartSelect
              .get(`#select-parallel-${state.uniqueId} .smart-select`)
              .setValue(state.cardInfoLocal.parallelId)

            state.needsToLoadParallels = false
          } else {
            state.cardInfoLocal.sportId = 0
            // f7.smartSelect.get('#select-sport .smart-select').setValue('')
            state.cardInfoLocal.year = ''
            state.cardInfoLocal.parallelId = 0
            f7.smartSelect
              .get(`#select-parallel-${state.uniqueId} .smart-select`)
              .setValue('')

            state.parallels = []
          }
        } catch {}
      }
    }

    const setSmartSelectHTMLFormat = () => {
      const contentElements = document.querySelectorAll<HTMLElement>(
        '.smart-select .item-content'
      )
      const titleElements = document.querySelectorAll<HTMLElement>(
        '.smart-select .item-title'
      )
      const valueElements = document.querySelectorAll<HTMLElement>(
        '.smart-select .item-after'
      )

      contentElements.forEach((contentElement) => {
        contentElement.classList.add('item-input')
        contentElement.style.height = '67px'
      })

      titleElements.forEach((titleElement) => {
        titleElement.classList.remove('item-title')
        titleElement.classList.add('item-label')
      })

      valueElements.forEach((valueElement) => {
        valueElement.classList.remove('item-after')
        valueElement.classList.add('item-subtitle')
        valueElement.style.margin = '9.5px 0px'
      })
    }

    const clickInfoDependent = (
      event: Event,
      field: 'product' | 'year' | 'sport' | 'parallel'
    ) => {
      if (field === 'product' && !state.cardInfoLocal.manufacturerId) {
        event.stopImmediatePropagation()

        f7.dialog.alert('Please select manufacurer before selecting product')
      } else if (
        (field === 'year' || field === 'sport') &&
        !state.cardInfoLocal.productId
      ) {
        event.stopImmediatePropagation()

        f7.dialog.alert(`Please select product before selecting ${field}`)
      } else if (
        field === 'parallel' &&
        (!state.cardInfoLocal.year || !state.cardInfoLocal.sportId)
      ) {
        event.stopImmediatePropagation()

        f7.dialog.alert(
          'Please select sport and year before selecting parallel'
        )
      }
    }

    const resetCardInfo = () => {
      state.cardInfoLocal = {
        id: 0,
        image: '',
        cardNumber: 'N/A',
        cardSerialNumber: 'N/A',
        year: '',
        manufacturerId: 0,
        sportId: 0,
        productId: 0,
        parallelId: 0,
        productYearSportId: 0,
        playerName: '',
      }

      state.cardScore = 0
    }

    const addCard = async ({
      imageSrc,
      isEdit = false,
    }: {
      imageSrc: ImageSrc
      isEdit?: boolean
    }) => {
      f7.dialog.preloader('Adding card')

      const {
        playerName,
        manufacturerId,
        year,
        sportId,
        productId,
        parallelId,
        // productYearSportId,
        cardName,
        cardNumber,
        cardSerialNumber,
        id: cardId,
      } = state.cardInfoLocal

      let dependentValueNotEntered = ''

      if (manufacturerId && (!productId || !year || !sportId || !parallelId)) {
        if (!productId) {
          dependentValueNotEntered = 'product'
        } else if (!year) {
          dependentValueNotEntered = 'year'
        } else if (!sportId) {
          dependentValueNotEntered = 'sport'
        } else if (!parallelId) {
          dependentValueNotEntered = 'parallel'
        }

        if (dependentValueNotEntered) {
          f7.dialog.alert(`Please select a ${dependentValueNotEntered}`)
        }
      }
      // const { id: productYearSportId } = state.productYearsSports.find(
      //   (productYearSport) => {
      //     return (
      //       productYearSport.sport === state.cardInfo.sport &&
      //       productYearSport.year === state.cardInfo.year
      //     )
      //   }
      // )

      let blobFront
      let blobBack

      const files = []

      if (imageSrc.front) {
        blobFront = await fetch(imageSrc.front).then((r) => r.blob())

        files.push(blobFront)
      }
      if (imageSrc.back) {
        blobBack = await fetch(imageSrc.back).then((r) => r.blob())

        files.push(blobBack)
      }

      const data = {
        playerName,
        // productYearSportId: productYearSportId
        //   ? productYearSportId.toString()
        //   : null,
        // productYearSportId: productYearSportId || null,
        parallelId: parallelId || null,
        rating: state.cardScore,
        cardNumber,
        cardSerialNumber,
      }

      // const queryString = `${new URLSearchParams(data)}`

      try {
        await ff.patch(`/cards/${cardId}`, {
          // files: files.length > 0 ? files : null,
          data: { cardName },
          errorMsgNoun: 'card',
        })

        f7.preloader.hide()

        // await globalStore.dispatch('FolderModule/getFolders')

        state.addInfoPopupOpened = false
        state.gradeCardPopupOpened = false

        // f7.dialog.alert('Card successfully added')

        emit('updateSuccess')

        if (!isEdit) {
          resetCardInfo()
        }

        // const { router } = f7.views.current

        // router.navigate({ name: 'saved-cards' }, { animate: false })

        // // switchToSavedCardsTab()
      } catch {}

      f7.dialog.close()
    }

    watch(
      () => state.cardInfoLocal.manufacturerId,
      async () => {
        if (state.cardInfoLocal.manufacturerId) {
          await getProducts()
        }
      }
    )

    watch(
      () => state.cardInfoLocal.sportId,
      async () => {
        if (state.cardInfoLocal.sportId) {
          // Get all available product years sports for given sport
          const productYearsSportsAvailable = state.productYearsSports.filter(
            (productYearSport) => {
              return (
                productYearSport.sportId ===
                parseInt(state.cardInfoLocal.sportId, 10)
              )
            }
          )

          state.productYears = productYearsSportsAvailable.map(
            ({ year }) => year
          )

          if (!state.productYears.includes(state.cardInfoLocal.year)) {
            state.cardInfoLocal.year = ''
          }
        }

        if (state.cardInfoLocal.sportId && state.cardInfoLocal.year) {
          await getProductYearSportIdSelected()

          await getParallels()
        }
      }
    )

    watch(
      () => state.cardInfoLocal.year,
      async () => {
        if (state.cardInfoLocal.year) {
          // Get all available product years sports for given year
          const productYearsSportsAvailable = state.productYearsSports.filter(
            (productYearSport) => {
              return productYearSport.year === state.cardInfoLocal.year
            }
          )

          state.productSports = productYearsSportsAvailable.map(
            ({ sport, sportId }) => ({
              id: sportId,
              sport,
            })
          )

          if (
            !state.productSports.some(
              ({ id }) => id === state.cardInfoLocal.sportId
            )
          ) {
            state.cardInfoLocal.sportId = 0
            // state.cardInfoLocal.sport.id = 0
            // f7.smartSelect.get('#select-sport .smart-select').setValue('')
          }
        }

        if (state.cardInfoLocal.sportId && state.cardInfoLocal.year) {
          await getProductYearSportIdSelected()

          await getParallels()
        }
      }
    )

    watch(
      () => state.cardInfoLocal,
      () => {
        emit('update:cardInfo', state.cardInfoLocal)
      },
      { deep: true }
    )

    watch(
      () => props.cardInfo,
      () => {
        if (!isEqual(props.cardInfo, state.cardInfoLocal)) {
          state.cardInfoLocal = cloneDeep(props.cardInfo)

          // if (!props.cardInfo.productId) {
          //   f7.smartSelect
          //     .get(`#select-product-${state.uniqueId} .smart-select`)
          //     .setValue('')
          // }

          // if (!props.cardInfo.parallelId) {
          //   f7.smartSelect
          //     .get(`#select-parallel-${state.uniqueId} .smart-select`)
          //     .setValue('')
          // }
        }
      },
      { deep: true }
    )

    onMounted(async () => {
      // await getManufacturers()

      // if (state.cardInfoLocal.manufacturerId) {
      //   await getProducts()
      // }

      // f7ready(async () => {
      //   setSmartSelectHTMLFormat()

      //   if (state.cardInfoLocal.parallelId) {
      //     state.needsToLoadParallels = true
      //   }

      //   if (state.cardInfoLocal.productId) {
      //     f7.smartSelect
      //       .get(`#select-product-${state.uniqueId} .smart-select`)
      //       .setValue(state.cardInfoLocal.productId)
      //   }
      // })
    })

    return {
      ...toRefs(state),
      getManufacturers,
      getProductYearsSports,
      getProducts,
      getParallels,
      clickInfoDependent,
      addCard,
    }
  },
})
</script>

<style>
.smart-select-page li.disabled {
  display: none;
}

.smart-select.item-link .item-inner:before {
  content: '';
  pointer-events: none;
  position: absolute;
  top: 50%;
  margin-top: -2px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid #727272;
  right: 6px;
}
</style>
