import { f7 } from 'framework7-vue';
import FarFetch, { FarFetchError } from '@websitebeaver/far-fetch';
// import { useStore } from 'vuex'
import globalStore from '@/store';
// import globalStore from '@/store'
// import Login from '@/models/Login';
// import SubscribePopup from '@/models/SubscribePopup';
export { FarFetchError };
export default new FarFetch({
    baseURL: 'https://api.playergraders.com',
    beforeSend() {
        // Use authorization header if token set in localStorage
        if (localStorage.getItem('token')) {
            return {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            };
        }
    },
    errorHandler({ error, userMessage, response }) {
        if (response?.status === 401) {
            f7.dialog.alert('You are not authroized');
            // Login.create({ data: { is_logged_in: false } });
            globalStore.commit('LoginModule/signOut');
            // localStorage.removeItem('userId')
            // localStorage.removeItem('token')
        }
        else if (response?.status === 402) {
            f7.dialog.alert('You do not have an active subscription. Purchase a plan to continue using MusAff', () => {
                // Login.create({ data: { is_logged_in: false } });
                // SubscribePopup.create({ data: { isOpened: true } });
                globalStore.commit('LoginModule/signOut');
                // localStorage.removeItem('userId')
                // localStorage.removeItem('token')
            });
        }
        else {
            f7.dialog.alert(userMessage); // Error message from either errorMsg or errorMsgNoun will be used
        }
    },
});
