<template>
  <f7-page>
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-left>
        <f7-link text="Back" href="/saved-cards/" icon-f7="chevron_left" />

        <template v-if="checkedCardIds.length > 0">
          <f7-link @click="chooseFolderMove()">Move to Folder</f7-link>

          <f7-link @click="deleteCards()">Delete Cards</f7-link>
        </template>
      </f7-nav-left>
      <f7-nav-title sliding>{{ startCase(folderNameSlug) }}</f7-nav-title>
      <f7-nav-right>
        <!-- <f7-link icon-f7="search" @click="onSearchOpen()"></f7-link> -->
      </f7-nav-right>

      <!-- <f7-searchbar
        :id="`search-bar-cards-${folderId}`"
        v-model:value="searchText"
        expandable
        custom-search
      /> -->
    </f7-navbar>

    <f7-popup
      v-model:opened="cardPopupOpened"
      swipe-to-close
      @popup:open="onCardPopupOpen()"
      @popup:close="onCardPopupClose()"
    >
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <!-- <f7-link>Edit</f7-link> -->
          </f7-nav-left>
          <f7-nav-title>Card Info</f7-nav-title>
          <f7-nav-right>
            <f7-link @click="cardPopupOpened = false">Close</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-block
          class="flex flex-col items-center mx-auto"
          style="width: 500px"
        >
          <f7-button @click="updateCard()">Update</f7-button>

          <card-info
            v-if="cardPopupOpened"
            ref="cardInfoComponent"
            v-model:card-info="cardInfo"
            class="w-full"
            @update-success="onUpdateCardSuccess()"
          />

          <card-image
            ref="cardImageComponentFront"
            v-model:image-src="cardInfo.imageSrc.front"
            :defects="cardInfo.frontDefects"
            :has-upload="false"
            side="front"
          >
            <template #betweenTitleAndImage>
              <div class="flex justify-center">
                <h3 class="font-bold text-2xl mb-6">Defects:</h3>

                <p class="ml-4 text-2xl text-color-primary">
                  {{ cardInfo.frontDefects.length }}
                </p>
              </div>
            </template>
          </card-image>

          <card-image
            ref="cardImageComponentBack"
            v-model:image-src="cardInfo.imageSrc.back"
            :defects="cardInfo.backDefects"
            :has-upload="false"
            side="back"
          >
            <template #betweenTitleAndImage>
              <div class="flex justify-center">
                <h3 class="font-bold text-2xl mb-6">Defects:</h3>

                <p class="ml-4 text-2xl text-color-primary">
                  {{ cardInfo.backDefects.length }}
                </p>
              </div>
            </template>
          </card-image>

          <!-- Card Swiper -->
          <!-- <f7-swiper
            :pagination="{ clickable: true }"
            navigation
            effect="flip"
            :loop="true"
            :observer="true"
            :observe-parents="true"
            :grab-cursor="true"
            style="width: 350px"
          >
            <f7-swiper-slide>
              <img class="mx-auto" :src="cardInfo.imageSrc.front" />
            </f7-swiper-slide>
            <f7-swiper-slide>
              <img class="mx-auto" :src="cardInfo.imageSrc.back" />
            </f7-swiper-slide>
          </f7-swiper> -->
        </f7-block>
      </f7-page>
    </f7-popup>

    <f7-block strong inset class="grid grid-cols-3 gap-5">
      <!-- <img
        src="https://cconnect.s3.amazonaws.com/wp-content/uploads/2016/02/1992-93-Topps-Shaquille-ONeal-RC-362.jpg"
        class="mx-auto"
        style="height: 80px"
        @click="cardPopupOpened = true"
      /> -->
      <div
        v-for="card in cards.data"
        :key="card.id"
        class="flex items-start mx-auto"
      >
        <f7-checkbox
          :value="card.id"
          :checked="checkedCardIds.includes(card.id)"
          @change="onCheckChange"
        />

        <div
          class="flex flex-col justify-center align-center"
          style="width: 150px"
        >
          <f7-link
            :disabled="cardPopupIsClicked"
            @click="
              !cardPopupIsClicked &&
                openCardPopup({ cardId: card.id, event: $event })
            "
          >
            <img
              :src="card.image"
              style="height: 80px"
              @taphold="openCardInfoActionSheet({ cardId: card.id })"
            />
          </f7-link>

          <p
            v-if="card.cardName"
            class="overflow-ellipsis overflow-hidden whitespace-nowrap text-center font-bold mt-2"
          >
            {{ card.cardName }}
          </p>
        </div>
      </div>
    </f7-block>

    <f7-actions v-model:opened="cardInfoActionSheetOpened">
      <f7-actions-group>
        <f7-actions-button bold @click="chooseFolderMove()"
          >Move to Folder</f7-actions-button
        >

        <f7-actions-button bold @click="deleteCards()"
          >Delete Folder</f7-actions-button
        >
      </f7-actions-group>

      <f7-actions-group>
        <f7-actions-button color="gray">Cancel</f7-actions-button>
      </f7-actions-group>
    </f7-actions>

    <f7-popup v-model:opened="chooseFolderMovePopupOpened" swipe-to-close>
      <f7-page>
        <f7-navbar title="Swipe To Close">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-list media-list>
          <f7-list-item
            title="Default Folder"
            :after="`${folders.default?.total}`"
            @click="
              moveCardToNewFolder({
                folderId: 0,
                folderNameSlug: 'default-folder',
              })
            "
          >
            <template #media>
              <img
                src="@/assets/folder-icon.png"
                style="width: 56px; height: 56px"
              />

              <!-- <f7-icon size="56px" f7="folder_fill" /> -->
            </template>
          </f7-list-item>
        </f7-list>

        <f7-list media>
          <template
            v-for="(folderTypeValue, folderTypeName) in folders"
            :key="folderTypeName"
          >
            <template v-if="folderTypeName === 'folders'">
              <f7-list-item
                v-for="{ id, name, total, slug, image } in folderTypeValue.data"
                :key="id"
                :title="name"
                :after="`${total}`"
                @click="
                  moveCardToNewFolder({
                    folderId: id,
                    folderNameSlug: slug,
                  })
                "
              >
                <template #media>
                  <img
                    src="@/assets/folder-icon.png"
                    style="width: 56px; height: 56px"
                  />
                  <!-- <img v-if="image" :src="image" style="height: 80px" />

                  <f7-icon v-else size="56px" f7="folder_fill" /> -->
                </template>
              </f7-list-item>
            </template>
          </template>
        </f7-list>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, toRefs, watch } from 'vue'

import { Capacitor } from '@capacitor/core'

// import { f7 } from 'framework7-vue'

import ff from '@/js/far-fetch-init'
import { f7 } from 'framework7-vue'

import startCase from 'lodash.startcase'
import kebabCase from 'lodash.kebabcase'

import { v4 as uuidv4 } from 'uuid'

import CardImage from '@/components/card-image.vue'
import CardInfo from '@/components/card-info.vue'

const { isNativePlatform } = Capacitor

export default defineComponent({
  components: {
    CardImage,
    CardInfo,
  },
  props: {
    // folderId: {
    //   type: String,
    //   default: '0',
    // },
    folderNameSlug: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const cardInfoComponent = ref<InstanceType<typeof CardInfo>>()
    const cardImageComponentFront = ref<InstanceType<typeof CardImage>>()
    const cardImageComponentBack = ref<InstanceType<typeof CardImage>>()

    const state = reactive({
      searchText: '',
      cardPopupOpened: false,
      cardPopupIsClicked: false,
      cards: { data: [], meta: {} },
      folders: [],
      cardInfo: {
        id: 0,
        imageSrc: { front: '', back: '' },
        frontDefects: [],
        backDefects: [],
        cardName: '',
        cardNumber: 'N/A',
        cardSerialNumber: 'N/A',
        year: '',
        manufacturerId: 0,
        sportId: '',
        productId: 0,
        parallelId: 0,
        productYearSportId: 0,
        playerName: '',
        cardScore: 0,
      },
      cardInfoActionSheetOpened: false,
      chooseFolderMovePopupOpened: false,
      checkedCardIds: [],
      // folderName: '',
      folderId: 0,
      uniqueId: uuidv4(),
      // chooseFolderCardId: 0,
    })

    const getCardsInFolder = async () => {
      try {
        const { responseJSON } = await ff.get(
          `/folders/${props.folderNameSlug}`,
          {
            errorMsgNoun: 'cards',
          }
        )

        state.cards = responseJSON

        state.folderId = responseJSON.meta.folderId

        // state.folderName = parseInt(props.folderId, 10)
        //   ? responseJSON.meta.folderName
        //   : 'Default Folder'
      } catch {}
    }

    const deleteCards = async () => {
      f7.dialog.confirm(
        'Are you sure you want to delete this card?',
        async () => {
          try {
            await ff.delete('/cards', {
              errorMsgNoun: 'cards',
              data: { cardIds: state.checkedCardIds },
            })

            state.cards.data = state.cards.data.filter(
              ({ id }) => !state.checkedCardIds.includes(id)
            )

            state.checkedCardIds = []

            f7.dialog.alert('Cards are deleted', () => {
              const { router } = f7.views.current

              router.navigate({ name: 'trash-inner' })
            })
          } catch {}
        }
      )
    }

    const openCardPopup = async ({
      cardId,
      event,
    }: {
      cardId: number
      event: Event
    }) => {
      if (state.cardPopupIsClicked) return

      state.cardPopupIsClicked = true

      f7.dialog.preloader('Processing image')

      try {
        const { responseJSON } = await ff.get(`/cards/${cardId}`, {
          errorMsgNoun: 'card',
        })

        state.cardInfo = responseJSON

        // state.cardInfo.image = image

        state.cardPopupOpened = true
      } catch {}
    }

    const getFolders = async () => {
      try {
        const { responseJSON } = await ff.get('/folders', {
          errorMsgNoun: 'folders',
        })

        state.folders = responseJSON
      } catch {}
    }

    const openCardInfoActionSheet = ({ cardId }: { cardId: number }) => {
      state.checkedCardIds = []

      state.checkedCardIds.push(cardId)

      state.cardInfoActionSheetOpened = true
    }

    const chooseFolderMove = async () => {
      await getFolders()

      state.chooseFolderMovePopupOpened = true
    }

    const moveCardToNewFolder = async ({
      folderNameSlug,
      folderId,
    }: {
      folderNameSlug: string
      folderId: number
    }) => {
      try {
        await ff.patch(`/cards`, {
          data: { folderId, cardIds: state.checkedCardIds },
          errorMsgNoun: 'moving card',
        })

        state.chooseFolderMovePopupOpened = false

        state.cards.data = state.cards.data.filter(
          ({ id }) => !state.checkedCardIds.includes(id)
        )

        state.checkedCardIds = []

        f7.dialog.alert('Cards are moved to new folder', () => {
          const { router } = f7.views.current

          router.navigate(`/folder-inner/${folderNameSlug}`)
        })
      } catch {}
    }

    const onSearchOpen = () => {
      const searchBar = f7.searchbar.get(`#search-bar-cards-${state.uniqueId}`)

      searchBar.inputEl.focus()

      searchBar.search(state.searchText).enable()
    }

    const onSearch = async () => {
      try {
        const { responseJSON } = await ff.get(
          `/folders/${props.folderNameSlug}`,
          {
            data: {
              filters: { playerName: state.searchText },
            },
            errorMsgNoun: 'cards',
          }
        )

        state.cards = responseJSON
      } catch {}
    }

    const onCheckChange = (event: Event) => {
      const { checked, value } = <HTMLInputElement>event.target

      const valueFormatted = parseInt(value, 10)

      if (checked) {
        state.checkedCardIds.push(valueFormatted)
      } else {
        state.checkedCardIds.splice(
          state.checkedCardIds.indexOf(valueFormatted),
          1
        )
      }
    }

    const updateCard = async () => {
      await cardInfoComponent.value.addCard({
        imageSrc: { front: null, back: null },
        isEdit: true,
      })
    }

    const onUpdateCardSuccess = () => {
      const cardIndex = state.cards.data.findIndex(
        ({ id }) => id === state.cardInfo.id
      )

      state.cards.data[cardIndex].cardName = state.cardInfo.cardName

      f7.dialog.alert('Card updated successfully')
    }

    const onCardPopupOpen = () => {
      cardImageComponentFront.value.resetCroppedImageLoaded()
      cardImageComponentBack.value.resetCroppedImageLoaded()
    }

    const onCardPopupClose = () => {
      state.cardInfo.imageSrc.front = ''
      state.cardInfo.imageSrc.back = ''

      state.cardInfo.frontDefects = []
      state.cardInfo.backDefects = []

      state.cardPopupIsClicked = false

      f7.dialog.close()
    }

    watch(
      () => state.searchText,
      async () => {
        await onSearch()
      }
    )

    onMounted(async () => {
      await getCardsInFolder()
    })

    return {
      ...toRefs(state),
      cardInfoComponent,
      cardImageComponentFront,
      cardImageComponentBack,
      startCase,
      kebabCase,
      isNativePlatform,
      getCardsInFolder,
      deleteCards,
      openCardPopup,
      openCardInfoActionSheet,
      chooseFolderMove,
      moveCardToNewFolder,
      onSearchOpen,
      onSearch,
      onCheckChange,
      updateCard,
      onUpdateCardSuccess,
      onCardPopupOpen,
      onCardPopupClose,
    }
  },
})
</script>
